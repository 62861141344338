//––––––––––––––––––––––––––––––––––––––––––––––––––
// Utility Classes
//––––––––––––––––––––––––––––––––––––––––––––––––––
.pull-left {
  float: left;
}

.no-wrap {
  white-space: nowrap;
}

.pull-right {
  float: right;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.circle {
  border-radius: 50%;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

}

.circle-small {
  @extend .circle;
  width: rem(25);
  height: rem(25);
}

.hide {
  display: none !important;
}

.hidden {
  display: none !important;
}

// Text Align
.left-align {
  text-align: left;
}

.right-align {
  text-align: right
}

.center, .center-align {
  text-align: center;
}

// No Text Select
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}

.bold {
  font-weight: $weight-bold;
}

// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}

.z-depth-0-half {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08), 0 2px 10px 0 rgba(0, 0, 0, 0.06);
}

.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

// Used for JS CSS reflows
.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-6 {
  margin-top: 6px;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}


.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 1px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-3 {
  padding-top: 3px;
}

.pt-4 {
  padding-top: 4px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-6 {
  padding-top: 6px;
}

.pt-7 {
  padding-top: 7px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-9 {
  padding-top: 9px;
}

.pt-10 {
  padding-top: 10px;
}

.alert {
  line-height: 25px;
  text-align: left;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

#dp-header {
  width: 250px !important;
  top: 46px !important;
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.shadow-none {
  box-shadow: none;
}

bg-transparent {
  background-color: transparent;
}

.bg-current {
  background-color: currentColor;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.bg-gray-200 {
  background-color: #edf2f7;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}

.bg-gray-400 {
  background-color: #cbd5e0;
}

.bg-gray-500 {
  background-color: #a0aec0;
}

.bg-gray-600 {
  background-color: #718096;
}

.bg-gray-700 {
  background-color: #4a5568;
}

.bg-gray-800 {
  background-color: #2d3748;
}

.bg-gray-900 {
  background-color: #1a202c;
}

.bg-red-100 {
  background-color: #fff5f5;
}

.bg-red-200 {
  background-color: #fed7d7;
}

.bg-red-300 {
  background-color: #feb2b2;
}

.bg-red-400 {
  background-color: #fc8181;
}

.bg-red-500 {
  background-color: #f56565;
}

.bg-red-600 {
  background-color: #e53e3e;
}

.bg-red-700 {
  background-color: #c53030;
}

.bg-red-800 {
  background-color: #9b2c2c;
}

.bg-red-900 {
  background-color: #742a2a;
}

.bg-orange-100 {
  background-color: #fffaf0;
}

.bg-orange-200 {
  background-color: #feebc8;
}

.bg-orange-300 {
  background-color: #fbd38d;
}

.bg-orange-400 {
  background-color: #f6ad55;
}

.bg-orange-500 {
  background-color: #ed8936;
}

.bg-orange-600 {
  background-color: #dd6b20;
}

.bg-orange-700 {
  background-color: #c05621;
}

.bg-orange-800 {
  background-color: #9c4221;
}

.bg-orange-900 {
  background-color: #7b341e;
}

.bg-yellow-100 {
  background-color: #fffff0;
}

.bg-yellow-200 {
  background-color: #fefcbf;
}

.bg-yellow-300 {
  background-color: #faf089;
}

.bg-yellow-400 {
  background-color: #f6e05e;
}

.bg-yellow-500 {
  background-color: #ecc94b;
}

.bg-yellow-600 {
  background-color: #d69e2e;
}

.bg-yellow-700 {
  background-color: #b7791f;
}

.bg-yellow-800 {
  background-color: #975a16;
}

.bg-yellow-900 {
  background-color: #744210;
}

.bg-green-100 {
  background-color: #f0fff4;
}

.bg-green-200 {
  background-color: #c6f6d5;
}

.bg-green-300 {
  background-color: #9ae6b4;
}

.bg-green-400 {
  background-color: #68d391;
}

.bg-green-500 {
  background-color: #48bb78;
}

.bg-green-600 {
  background-color: #38a169;
}

.bg-green-700 {
  background-color: #2f855a;
}

.bg-green-800 {
  background-color: #276749;
}

.bg-green-900 {
  background-color: #22543d;
}

.bg-teal-100 {
  background-color: #e6fffa;
}

.bg-teal-200 {
  background-color: #b2f5ea;
}

.bg-teal-300 {
  background-color: #81e6d9;
}

.bg-teal-400 {
  background-color: #4fd1c5;
}

.bg-teal-500 {
  background-color: #38b2ac;
}

.bg-teal-600 {
  background-color: #319795;
}

.bg-teal-700 {
  background-color: #2c7a7b;
}

.bg-teal-800 {
  background-color: #285e61;
}

.bg-teal-900 {
  background-color: #234e52;
}

.bg-blue-100 {
  background-color: #ebf8ff;
}

.bg-blue-200 {
  background-color: #bee3f8;
}

.bg-blue-300 {
  background-color: #90cdf4;
}

.bg-blue-400 {
  background-color: #63b3ed;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.bg-blue-600 {
  background-color: #3182ce;
}

.bg-blue-700 {
  background-color: #2b6cb0;
}

.bg-blue-800 {
  background-color: #2c5282;
}

.bg-blue-900 {
  background-color: #2a4365;
}

.bg-indigo-100 {
  background-color: #ebf4ff;
}

.bg-indigo-200 {
  background-color: #c3dafe;
}

.bg-indigo-300 {
  background-color: #a3bffa;
}

.bg-indigo-400 {
  background-color: #7f9cf5;
}

.bg-indigo-500 {
  background-color: #667eea;
}

.bg-indigo-600 {
  background-color: #5a67d8;
}

.bg-indigo-700 {
  background-color: #4c51bf;
}

.bg-indigo-800 {
  background-color: #434190;
}

.bg-indigo-900 {
  background-color: #3c366b;
}

.bg-purple-100 {
  background-color: #faf5ff;
}

.bg-purple-200 {
  background-color: #e9d8fd;
}

.bg-purple-300 {
  background-color: #d6bcfa;
}

.bg-purple-400 {
  background-color: #b794f4;
}

.bg-purple-500 {
  background-color: #9f7aea;
}

.bg-purple-600 {
  background-color: #805ad5;
}

.bg-purple-700 {
  background-color: #6b46c1;
}

.bg-purple-800 {
  background-color: #553c9a;
}

.bg-purple-900 {
  background-color: #44337a;
}

.bg-pink-100 {
  background-color: #fff5f7;
}

.bg-pink-200 {
  background-color: #fed7e2;
}

.bg-pink-300 {
  background-color: #fbb6ce;
}

.bg-pink-400 {
  background-color: #f687b3;
}

.bg-pink-500 {
  background-color: #ed64a6;
}

.bg-pink-600 {
  background-color: #d53f8c;
}

.bg-pink-700 {
  background-color: #b83280;
}

.bg-pink-800 {
  background-color: #97266d;
}

.bg-pink-900 {
  background-color: #702459;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-32 {
  padding: 8rem;
}

.p-40 {
  padding: 10rem;
}

.p-48 {
  padding: 12rem;
}

.p-56 {
  padding: 14rem;
}

.p-64 {
  padding: 16rem;
}

.p-px {
  padding: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.px-3 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.px-4 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-5 {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.px-6 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.px-8 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.px-10 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.px-12 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.px-16 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.px-20 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.px-24 {
  padding-right: 6rem;
  padding-left: 6rem;
}

.px-32 {
  padding-right: 8rem;
  padding-left: 8rem;
}

.px-40 {
  padding-right: 10rem;
  padding-left: 10rem;
}

.px-48 {
  padding-right: 12rem;
  padding-left: 12rem;
}

.px-56 {
  padding-right: 14rem;
  padding-left: 14rem;
}

.px-64 {
  padding-right: 16rem;
  padding-left: 16rem;
}

.px-px {
  padding-right: 1px;
  padding-left: 1px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pt-px {
  padding-top: 1px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pr-px {
  padding-right: 1px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pl-px {
  padding-left: 1px;
}

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.text-5xl {
  font-size: 3rem; /* 48px */
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}

.text-8xl {
  font-size: 6rem; /* 96px */
  line-height: 1;
}

.text-9xl {
  font-size: 8rem; /* 128px */
  line-height: 1;
}