//––––––––––––––––––––––––––––––––––––––––––––––––––
// Forms
//––––––––––––––––––––––––––––––––––––––––––––––––––

.input-inline {
    display: inline-block;
    position: relative;
    top: -(rem(15));
    //margin: 0px 20px;
    height: 1rem;
}

.input-field .checkbox-label
{
    color: $font-dark;
} 

// New

/* Remove Focus Boxes */
select:focus {
  outline: 1px solid lighten($button-color, 47%);
}
button:focus {
  outline: none;
  background-color: lighten($button-color, 4%);
}

label {
  font-size: $label-font-size;
  color: $input-border-color;
}

/***************************
   Text Inputs + Textarea
****************************/

// Style Placeholders
::-webkit-input-placeholder {
   color: $input-border-color;
}

:-moz-placeholder { /* Firefox 18- */
   color: $input-border-color;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: $input-border-color;
}

:-ms-input-placeholder {
   color: $input-border-color;
}

// Text inputs
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search],
textarea.input-textarea,
textarea.tixby-textarea {

  // General Styles
  background-color: white;
  border: solid 1px #EEEEEE;
  border-bottom: 2px solid $input-border-color;
  border-radius: 0;
  outline: none;
  height: auto;
  width: 100%;

  font-size: 1rem;
  margin: 0 0 15px 0;
  padding: 1.8rem 0.625rem .625rem .625rem;
  box-shadow: none;
  @include box-sizing(border-box);
  //transition: all .3s;


  // Disabled input style
  &:disabled, &[readonly="readonly"] {
    color: $input-disabled-color;
    border-bottom: 2px dotted $input-disabled-color;
  }
  // Disabled label style
  &:disabled+label, &[readonly="readonly"]+label {
    color: $input-disabled-color;
  }
  // Focused input style
  &:focus:not([readonly]) {
    border-bottom: 2px solid $input-focus-color;
    box-shadow: 0 1px 0 0 $input-focus-color;
  }
  // Focused label style
  &:focus:not([readonly])+label {
      
    //color: $input-focus-color;
    color: $orange;
  }
  // Valid Input Style
  &.valid,
  &:focus.valid {
    border-bottom: 2px solid $input-success-color;
    box-shadow: 0 1px 0 0 $input-success-color;
  }
  // Custom Success Message
  &.valid + label:after,
  &:focus.valid + label:after {
    content: attr(data-success);
    color: $input-success-color;
    opacity: 1;
  }
  // Invalid Input Style
  &.invalid,
  &:focus.invalid {
    border-bottom: 2px solid $input-error-color;
    box-shadow: 0 1px 0 0 $input-error-color;
  }
  // Custom Error message
  &.invalid + label:after,
  &:focus.invalid + label:after {
    content: attr(data-error);
    color: $input-error-color;
    opacity: 1;
  }

  // Form Message Shared Styles
  & + label:after {
    display: block;
    content: "";
    position: absolute;
    top: 65px;
    opacity: 0;
    transition: .2s opacity ease-out, .2s color ease-out;
  }
}

// Styling for input field wrapper
.input-field {
  position: relative;
  margin-top: 1rem;
  &.input-field-inline
  {
      display: inline-block;
  }
  &.input-field-small
  {
      max-width: 35%;
  }
  
  &.input-field-medium
  {
      max-width: 60%;
  }
  
  &.input-field-large
  {
      max-width: 100%;
  }

  label {
    color: $input-border-color;
    position: absolute;
    top: 1.4rem;
    left: rem(10);
    font-size: 1rem;
    cursor: text;
    font-weight: 300;
    @include transition(.2s ease-out);
  }
  label.active {
    font-size: $label-font-size;
    font-weight: 400;
    @include transform(translateY(-90%));
  }

  // Prefix Icons
  .prefix {
    position: absolute;
    width: 3rem;
    font-size: 2rem;
    @include transition(color .2s);

    &.active { color: $input-focus-color; }
  }
  .prefix ~ input,
  .prefix ~ textarea {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem);
  }
  .prefix ~ textarea { padding-top: .8rem; }
  .prefix ~ label { margin-left: 3rem; }

  @media #{$medium-and-down} {
    .prefix ~ input {
      width: 86%;
      width: calc(100% - 3rem);
    }
  }
  @media #{$small-and-down} {
    .prefix ~ input {
      width: 80%;
      width: calc(100% - 3rem);
    }
  }
}

// Search Field
.input-field input[type=search] {
  display: block;
  line-height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem);

  &:focus {
    background-color: $input-bg-color;
    border: 0;
    box-shadow: none;
    color: #444;

    & + label i,
    & ~ .mdi-navigation-close,
    & ~ .material-icons {
      color: #444;
    }
  }

  & + label {
    left: 1rem;
  }

  & ~ .mdi-navigation-close,
  & ~ .material-icons {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: 2rem;
    transition: .3s color;
  }
}


// Default textarea
textarea {
   width: 100%;
   height: 3rem;
   background-color: transparent;

  &.input-textarea, &.tixby-textarea {
    overflow-y: hidden; /* prevents scroll bar flash */
    resize: none;
    min-height: 3rem;
    box-sizing: content-box;
  }
}

// For textarea autoresize
.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word; /* future version of deprecated 'word-wrap' */
  padding-top: 1.2rem; /* prevents text jump on Enter keypress */
}


/***************
  Radio Buttons
***************/

/* Remove default Radio Buttons */
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  @include transition(.28s ease);

  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  @include transition(.28s ease);
}

/* Unchecked styles */
[type="radio"]:not(:checked) + label:before {
  border-radius: 50%;
  border: 2px solid $radio-empty-color;
}
[type="radio"]:not(:checked) + label:after {
  border-radius: 50%;
  border: 2px solid $radio-empty-color;
  z-index: -1;

  @include transform(scale(0));
}

/* Checked styles */
[type="radio"]:checked + label:before {
  border-radius: 50%;
  border: 2px solid transparent;
}
[type="radio"]:checked + label:after {
  border-radius: 50%;
  border: 2px solid $radio-fill-color;
  background-color: $radio-fill-color;
  z-index: 0;
  @include transform(scale(1.02));
}

/* Radio With gap */
[type="radio"].with-gap:checked + label:before {
  border-radius: 50%;
  border: 2px solid $radio-fill-color;
}
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%;
  border: 2px solid $radio-fill-color;
  background-color: $radio-fill-color;
  z-index: 0;
  @include transform(scale(.5));
}

/* Disabled Radio With gap */
[type="radio"].with-gap:disabled:checked + label:before {
  border: 2px solid $input-disabled-color;
}
[type="radio"].with-gap:disabled:checked + label:after {
  border: none;
  background-color: $input-disabled-color;
}

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: $input-disabled-color;
}
[type="radio"]:disabled + label {
  color: $input-disabled-color;
}
[type="radio"]:disabled:not(:checked) + label:before {
  border-color: $input-disabled-color;
}
[type="radio"]:disabled:checked + label:after {
  background-color: $input-disabled-color;
  border-color: $input-disabled-solid-color;
}

/***************
   Checkboxes
***************/

/* CUSTOM CSS CHECKBOXES */
form p {
  margin-bottom: 10px;
  text-align: left;
}
form p:last-child {
  margin-bottom: 0;
}

/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

// Checkbox Styles
[type="checkbox"] {

  // Text Label Style
  + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 22px;
    font-size: 1rem;
    top:0;

    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }

  /* checkbox aspect */
  + label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid $radio-empty-color;
    border-radius: 1px;
    margin-top: 2px;
    @include transition(.2s);
  }

  &:not(:checked):disabled + label:before {
    border: none;
    background-color: $input-disabled-color;
  }
}

[type="checkbox"]:checked {
  + label:before {
    top: -4px;
    left: -3px;
    width: 12px; height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid $radio-fill-color;
    border-bottom: 2px solid $radio-fill-color;
    @include transform(rotate(40deg));
    -webkit-backface-visibility: hidden;
    @include transform-origin(100% 100%);
  }

  &:disabled + label:before {
    border-right: 2px solid $input-disabled-color;
    border-bottom: 2px solid $input-disabled-color;
  }
}

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate {
  +label:before {
    left: -10px;
    top: -11px;
    width: 10px; height: 22px;
    border-top: none;
    border-left: none;
    border-right: 2px solid $radio-fill-color;
    border-bottom: none;
    @include transform(rotate(90deg));
    -webkit-backface-visibility: hidden;
    @include transform-origin(100% 100%);
  }

  // Disabled indeterminate
  &:disabled + label:before {
    border-right: 2px solid $input-disabled-color;
    background-color: transparent;
  }
}

// Filled in Style
[type="checkbox"].filled-in {
  // General
  + label:after {
    border-radius: 2px;
  }
  + label:before,
  + label:after {
    content: '';
    left: 0;
    position: absolute;
    /* .1s delay is for check animation */
    transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
    z-index: 1;
  }

  // Unchecked style
  &:not(:checked) + label:before {
    width: 0;
     height: 0;
     border: 3px solid transparent;
     left: 6px;
     top: 10px;

     -webkit-transform: rotateZ(37deg);
             transform: rotateZ(37deg);
     -webkit-transform-origin: 20% 40%;
             transform-origin: 100% 100%;
  }

  &:not(:checked) + label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid $radio-empty-color;
    top: 0px;
    z-index: 0;
  }

  // Checked style
  &:checked {
    + label:before {
      top: 0;
      left: 1px;
      width: 8px;
      height: 13px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid $input-bg-color;
      border-bottom: 2px solid $input-bg-color;
      -webkit-transform: rotateZ(37deg);
      transform: rotateZ(37deg);

      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }

    + label:after {
      top: 0px;
      width: 20px;
      height: 20px;
      border: 2px solid $orange;
      background-color: $orange;
      z-index: 0;
    }
  }
  // Disabled style
  &:disabled:not(:checked) + label:before {
    background-color: transparent;
    border: 2px solid transparent;
  }

  &:disabled:not(:checked) + label:after {
    border-color: transparent;
    background-color: $input-disabled-solid-color;
  }

  &:disabled:checked + label:before {
    background-color: transparent;
  }

  &:disabled:checked + label:after {
    background-color: $input-disabled-solid-color;
    border-color: $input-disabled-solid-color;
  }
}

/***************
     Switch
***************/
.switch,
.switch * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.switch label {
    cursor: pointer;
}

.switch label input[type=checkbox]{
    opacity: 0;
    width: 0;
    height: 0;
}

.switch label input[type=checkbox]:checked + .lever {
    background-color: $switch-checked-lever-bg;
}

.switch label input[type=checkbox]:checked + .lever:after {
    background-color: $switch-bg-color;
}

.switch label .lever {
    content: "";
    display: inline-block;
    position: relative;
    width: 40px;
    height: 15px;
    background-color: $switch-unchecked-lever-bg;
    border-radius: 15px;
    margin-right: 10px;
    transition: background 0.3s ease;
    vertical-align: middle;
    margin: 0 16px;
}

.switch label .lever:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: $switch-unchecked-bg;
    border-radius: 21px;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,.4);
    left: -5px;
    top: -3px;
    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
}

// Switch active style
input[type=checkbox]:checked:not(:disabled) ~ .lever:active:after {
       box-shadow: 0 1px 3px 1px rgba(0,0,0,.4), 0 0 0 15px transparentize($switch-bg-color, .9);
}

input[type=checkbox]:not(:disabled) ~ .lever:active:after {
       box-shadow: 0 1px 3px 1px rgba(0,0,0,.4), 0 0 0 15px rgba(0, 0, 0, .08);
}

.switch label input[type=checkbox]:checked + .lever:after {
    left: 24px;
}

// Disabled Styles
.switch input[type=checkbox][disabled] + .lever{
  cursor: default;
}
.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
    background-color: $input-disabled-solid-color;
}

/***************
  Select Field
***************/
.select-label {
  position: absolute;
}

.select-wrapper > span.caret{
  cursor: pointer;
  pointer-events: none;
}

.select-wrapper {
    position: relative;
    margin-bottom: rem(15);

    border: 1px solid transparent;
    box-sizing: border-box;
    
    .dropdown-content   
    {
        & li {
            width: auto;
            
            &.disabled
            {  
                 span {
                    color: $gray-on-dark !important;
                  } 
            }
            
            &.optgroup
            {
                background-color: transparent !important;
                
                span {
                    color: $orange !important;
                    //font-weight: $weight-bold;
                }
            }
        }
        
        & li > span
        {
           text-transform: none;
            font-size: 1rem;
            padding: 1rem; 
        }
    }

  input.select-dropdown {
    
    position: relative;
    cursor: pointer;
    background-color: white;
    border: none;
    border-bottom: 2px solid #acb1be;
    outline: 0;
    /* height: 3rem; */
    /* line-height: 3rem; */
    width: 100%;
    font-size: 1rem;
    margin: 0 0 15px;
    padding: 1.8rem 0 .625rem .625rem;
    display: block;
    box-sizing: border-box;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }

  span.caret {
    color: initial;
    position: absolute;
    right: 10px;
    top: 24px;
    font-size: 10px;
    z-index: 1;
    
    &.disabled {
      color: $input-disabled-color;
    }
  }

  & + label {
    
    position: absolute;
    top: 0.5rem;
    font-size: .75rem;
    font-weight:400;
  }
  
}

.input-field select { display: none; }
select.browser-default { display: block; }

// Disabled styles
select:disabled {
  color: rgba(0,0,0,.3);
}

.select-wrapper input.select-dropdown:disabled {
  color: rgba(0,0,0,.3);
  cursor: default;
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -ms-user-select: none; /* IE10+ */
  border-bottom: 1px solid rgba(0,0,0,.3);
}

.select-wrapper i {
  color: rgba(0,0,0,.3);
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: rgba(0,0,0,.3);
  background-color: transparent;
}

// Icons
.select-dropdown li {
  img {
    height: $dropdown-item-height - 10;
    width: $dropdown-item-height - 10;
    margin: 5px 15px;
    float: right;
  }
}

// Optgroup styles
.select-dropdown li.optgroup {
  border-top: 1px solid $dropdown-hover-bg-color;

  &.selected > span {
    color: rgba(0, 0, 0, .7);
  }

  & > span {
    color: rgba(0, 0, 0, .4);
  }

  & ~ li:not(.optgroup) {
    padding-left: 1rem;
  }
}

/*********************
      File Input
**********************/
.file-field {
  position: relative;

  .file-path-wrapper {
    outline: none;
    overflow: hidden;
    padding-left: 10px;
  }

  input.file-path { width: 100%; }

  .btn {
    float: left;
    margin-left: 0;
    //height: 3rem;
    //line-height: 3rem;
  }

  span {
    cursor: pointer;
  }

  input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

/***************************
   Text Inputs + Textarea
****************************/
select {
   background-color: rgba(255, 255, 255, 0.90);
   width: 100%;
   padding: 5px;
   border: 1px solid #f2f2f2;
   border-radius: 2px;
   height: 3rem;
}

//Expand .input-inline width for longer input fields. 
.long-input{
  width: 40%;
}

/***************************
   Custon select field
****************************/

.custom-select-field
{
    position:absolute; 
    top: 1.8rem;
    left: 0.625rem;
    
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    pointer-events: none; 
}

.pl-circle
{
    width:30px; 
    height:30px;
    float:left; 
    background-color:#333333;
    margin-top:-6px; 
    margin-right:10px;
    border-radius: 50%;
    box-sizing: border-box;
}

label#sub-domain-check:before{
  border-bottom-color: $green;
  border-right-color: $green;
}