.time-info{
  font-style: italic;
  font-size: 0.7rem;
  color: $gray-on-dark;
}

.note-text{
  margin-bottom: 5px !important;
}

.comment-content{
  display: block;
  padding: 1rem;
  background: $white;
  margin-bottom: 2rem;
}

.comment-header{
  border-bottom: 1px $light-grey solid;
  margin-bottom: 1rem;
  p{
    margin-bottom: 10px;
  }
}
.comment-human img{
  width: 100%;
}

.boxed-content{
  display: block;
  background: $white;
  padding-bottom: 2rem;
  margin-bottom: 4rem;
}