.max-h-0 {

  max-height: 0px;

}
.max-h-1 {

  max-height: 0.25rem;

}
.max-h-2 {

  max-height: 0.5rem;

}
.max-h-3 {

  max-height: 0.75rem;

}
.max-h-4 {

  max-height: 1rem;

}
.max-h-5 {

  max-height: 1.25rem;

}
.max-h-6 {

  max-height: 1.5rem;

}
.max-h-7 {

  max-height: 1.75rem;

}
.max-h-8 {

  max-height: 2rem;

}
.max-h-9 {

  max-height: 2.25rem;

}
.max-h-10 {

  max-height: 2.5rem;

}
.max-h-11 {

  max-height: 2.75rem;

}
.max-h-12 {

  max-height: 3rem;

}
.max-h-14 {

  max-height: 3.5rem;

}
.max-h-16 {

  max-height: 4rem;

}
.max-h-20 {

  max-height: 5rem;

}
.max-h-24 {

  max-height: 6rem;

}
.max-h-28 {

  max-height: 7rem;

}
.max-h-32 {

  max-height: 8rem;

}
.max-h-36 {

  max-height: 9rem;

}
.max-h-40 {

  max-height: 10rem;

}
.max-h-44 {

  max-height: 11rem;

}
.max-h-48 {

  max-height: 12rem;

}
.max-h-52 {

  max-height: 13rem;

}
.max-h-56 {

  max-height: 14rem;

}
.max-h-60 {

  max-height: 15rem;

}
.max-h-64 {

  max-height: 16rem;

}
.max-h-72 {

  max-height: 18rem;

}
.max-h-80 {

  max-height: 20rem;

}
.max-h-96 {

  max-height: 24rem;

}
.max-h-px {

  max-height: 1px;

}
.max-h-full {

  max-height: 100%;

}
.max-h-screen {

  max-height: 100vh;

}
.max-w-0 {

  max-width: 0rem;

}
.max-w-none {

  max-width: none;

}
.max-w-xs {

  max-width: 20rem;

}
.max-w-sm {

  max-width: 24rem;

}
.max-w-md {

  max-width: 28rem;

}
.max-w-lg {

  max-width: 32rem;

}
.max-w-xl {

  max-width: 36rem;

}
.max-w-2xl {

  max-width: 42rem;

}
.max-w-3xl {

  max-width: 48rem;

}
.max-w-4xl {

  max-width: 56rem;

}
.max-w-5xl {

  max-width: 64rem;

}
.max-w-6xl {

  max-width: 72rem;

}
.max-w-7xl {

  max-width: 80rem;

}
.max-w-full {

  max-width: 100%;

}
.max-w-min {

  max-width: min-content;

}
.max-w-max {

  max-width: max-content;

}
.max-w-prose {

  max-width: 65ch;

}
.max-w-screen-sm {

  max-width: 640px;

}
.max-w-screen-md {

  max-width: 768px;

}
.max-w-screen-lg {

  max-width: 1024px;

}
.max-w-screen-xl {

  max-width: 1280px;

}
.max-w-screen-2xl {

  max-width: 1536px;

}