.app-main {
  padding-bottom: 3rem;
}

.app-event {
  @extend .clearfix;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: rem(20);
  background-color: $white;
  display: flex;
  padding: rem(30);
  position: relative;
  cursor: pointer;
  //transition: $base-animation;
  border-left: 6px solid $status-gray;

  &:hover {
    @extend .z-depth-1;
  }
}

.app-event-lock {
  display: none;
  margin-left: 5px;
  top: 1px;
  padding-top: px;
  position: relative;
  right: 100;
}

.event-thumbnail {
  box-sizing: content-box;
  display: table-cell;
  vertical-align: middle;
  width: rem(75);
  height: rem(75);
  padding: rem(15);
  //background-color: $dark-grey;

  img {
    width: 100%;
    max-width: 100%;
  }

  &.small {
    width: rem(37);
    height: rem(37);
  }
}


.app-event-icon {
  @extend .event-thumbnail;
  float: left;
  padding: 0;
}

.event-thumbnail-desc {
  display: table-cell;
  white-space: normal;
  vertical-align: middle;
  padding: rem(15) rem(15) rem(15) rem(15);
}

.app-event-information {
  margin-left: rem(20);
  float: left;
  display: inline-block;
  color: $font-dark;

  h3 {
    margin-bottom: rem(5);
  }

  p {
    @extend %font-size-6;
    font-weight: $weight-bold;
    margin-bottom: rem(12);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @extend %font-size-5;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
        color: $orange;
      }
    }

    li {
      display: block;
      float: left;
      margin-bottom: 0;
      margin-left: rem(5);
      padding-left: rem(5);
      border-left: solid 1px $gray-on-dark;

      &:first-child {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
      }
    }
  }
}

.app-event-status {
  margin-left: auto;
  text-align: right;
  border-left: 1px solid $mid-gray;
  padding-left: rem(20);
}

.event-dropdown {
  position: absolute;
  right: -(rem(25));
  top: 0;
}

.status-indicator {
  margin-top: rem(8);

  &:first-child {
    margin-top: 0;
  }

  div {
    @extend .circle;
    box-sizing: border-box;
    width: rem(20);
    height: rem(20);
    border: 1px solid $gray-on-dark;
    background-color: $white;
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
  }

  h6 {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    margin-right: rem(10);
  }
}

.requests-list {
  position: relative;
  top: -(rem(10));
}

.requests-controls {
  margin-bottom: rem(100);
  text-align: center;

  ul {
    @extend .clearfix;
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      float: left;

      a {
        padding: rem(10);
        font-weight: 700;
        color: $orange;
        text-decoration: none;
        display: inline-block;
      }

      &.active {
        a {
          color: $font-dark;
          cursor: default;
        }
      }
    }
  }
}

.filter-controls {
  @extend .clearfix;

}

.filter-search {

  padding: 10px;
  border: none;
  //transition: all 0.3s;

  @extend %font-size-6;

  border: none !important;

  box-sizing: border-box;

  text-transform: none;
  text-decoration: none;

  padding: rem(5) rem(10);
  border-radius: rem(20);

  background-color: $mid-gray;

  color: $font-dark;

  width: 100%;

  &:focus {
    outline: none;
  }
}

.filter-search-icon {
  position: absolute;
  right: 1rem;
  top: 0.9rem;
  padding: 0.125rem;
  color: inherit;
  font-size: 1.3rem;
  color: $gray-on-dark;
  cursor: default !important;

}

.filter-controls-search {
  width: 30%;
  position: relative;

}

.filter-pills {
  max-width: 70%;

  ul {
    @extend .clearfix;
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      margin-left: rem(20);
      margin-bottom: rem(10);
      display: block;
      float: right;
    }
  }

  a {
    display: inline-block;
    @extend %font-size-6;
    text-transform: none;
    text-decoration: none;

    padding: rem(5) rem(10);
    border-radius: rem(20);
    color: inherit;
    background-color: $mid-gray;

    &.active {
      background-color: $orange;
      color: $white;
    }
  }
}

.team-pending-list tbody tr td:first-child span {
  margin-top: rem(7);
  display: inline-block;
}

.filters-container {
  min-height: 100px;
}

.remove-bottom-margin {
  margin-bottom: 2px;
}

#event_service_fees, #event_fees, #event-channel-payment-methods {
  border: 3px solid #666;
  padding: 10px;
  border-radius: 1px;
}

#service_fees_table, #event-channel-payment-methods-table, #fees_table {
  margin-bottom: 0;
}

.line-total td {
  font-weight: bold;
}

#search-orders {
  margin-top: 10px;
}

#empty {
  padding: 10px;
}

a.performance-actions:hover {
  text-decoration: underline !important;
}