.app-header-landing
{
    background: url(https://tixby-public.s3.amazonaws.com/img/party5low.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;

    .app-header-mid
    {
        text-align: center;
        margin-top: rem(80);
        margin-bottom: rem(80);
        
        p 
        {
            max-width: 700px;
            margin: auto;
            font-weight: 100;
            font-size: 1rem;
        }
        
    }

}
.app-header-company
{
    max-width: 200px;
    margin: auto;
    margin-top: rem(20);
    margin-bottom: rem(20);
    text-align: center;
    
    img
    {
        max-width: 100%;
    }
    i 
    {
        font-size: 100px;
    }
}

.app-header-overlay
{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background: rgba($dark-gray, .9);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.event-request-image
{
    img {
        max-width: 100%;
    }
    margin-bottom: rem(20);
}