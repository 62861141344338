.modal {
  @extend .z-depth-4;
  @extend .clearfix;

  display: none;
  position: relative;
  left: 0;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  width: 60%;
  margin: auto;

  will-change: top, opacity;

  @media #{$medium-and-down} {
    width: 80%;
  }

  .modal-footer {
    margin-top: rem(40);
    @extend .clearfix;
  }

  h1, h2, h3, h4 {
    margin-top: 0;
  }


  .modal-close {
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 1.25rem;
    color: $gray-on-dark;
  }
}

.modal-large {
  width: 80%;
}

.modal-medium {
  width: 25%;
}

.lean-overlay {
  position: fixed;
  z-index: 999;
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;

  will-change: opacity;
}

.modal-wrapper {
  padding: rem(40) 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2000;
  -webkit-overflow-scrolling: touch;
}


.modal-content {
  padding: rem(40);
  min-height: 400px;
  @extend .clearfix;
}