// Tables
//––––––––––––––––––––––––––––––––––––––––––––––––––

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid $light-grey;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

// Regular Tables
.regular-table {
  width: 100%;

  margin-bottom: rem(20);

  thead tr {
    background: transparent;
    border-bottom: 1px $gray-on-dark solid;
  }

  tbody tr:nth-child(even) {
    background: lighten($light-background, 3%);
  }

  tbody tr {

    transition: $base-animation;

  }


  th {
    @extend %font-size-6;
    border-bottom: none;
  }

  td {
    @extend %font-size-5;
    background-color: transparent;
    border-bottom: none;
    padding: rem(15);
  }


  .tr-subtext {
    font-weight: $weight-bold;
    @extend %font-size-6;
  }

}

// Small Tables
.small-table {
  width: 100%;

  margin-top: rem(20);
  margin-bottom: 0px;

  thead tr {
    background: transparent;
    border-bottom: 1px $gray-on-dark solid;
  }

  tbody tr {

    transition: $base-animation;

  }


  th {
    @extend %font-size-6;
    border-bottom: none;
    padding: rem(6);
  }

  td {
    @extend %font-size-6;
    background-color: transparent;
    border-bottom: none;
    padding: rem(6);
  }


  .tr-subtext {
    font-weight: $weight-bold;
    @extend %font-size-6;
  }

}


// Selectable Tables
//––––––––––––––––––––––––––––––––––––––––––––––––––

.selectable-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 rem(10);
  margin-bottom: rem(5);

  thead tr {
    background: transparent;
  }


  tbody tr {

    transition: $base-animation;
    background: $white;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $orange;
      color: $white;

      .performance-info ul li a {
        color: $white;
      }

      & i {
        color: $white;
      }

      .notification-circle {
        background-color: #ffffff !important;

        span {
          color: #333333 !important;
        }
      }
    }
  }

  th {
    @extend %font-size-6;
    border-bottom: none;
    white-space: nowrap;
  }

  td {
    position: relative;
    @extend %font-size-5;
    background-color: transparent;
    border-bottom: none;
    padding: rem(15);

    .event-dropdown i {
      color: $font-dark !important;
    }
  }

  tr td:first-child {
    //border-top-left-radius: $global-radius;
    //border-bottom-left-radius: $global-radius;
    display: table;
    border-collapse: collapse;
    width: 100%;

  }

  tr td:last-child {
    //border-top-right-radius: $global-radius;
    //border-bottom-right-radius: $global-radius;
  }

  .tr-subtext {
    font-weight: $weight-bold;
    @extend %font-size-6;
  }

}

.landing-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 rem(10);
  margin-bottom: rem(5);

  thead tr {
    background: transparent;
  }


  tbody tr {

    transition: $base-animation;
    background: $white;
    position: relative;
  }

  th {
    @extend %font-size-6;
    border-bottom: none;
    white-space: nowrap;
  }

  td {
    position: relative;
    @extend %font-size-5;
    background-color: transparent;
    border-bottom: none;
    padding: rem(15);

    .event-dropdown i {
      color: $font-dark !important;
    }
  }

  tr td:first-child {
    border-top-left-radius: $global-radius;
    border-bottom-left-radius: $global-radius;
  }

  tr td:last-child {
    border-top-right-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
  }

  .tr-subtext {
    font-weight: $weight-bold;
    @extend %font-size-6;
  }

}

.performance-list {
  position: relative;
  top: -0.625rem;

  //.selectable-table, .landing-table {
  //  tr td:first-child {
  //    padding: 0 !important;
  //    width: rem(100);
  //    background-color: pink;
  //  }
  //}
}

.performance-list td {
  padding: 0;
}

.app-header-mid .performance-date {
  border-right: 0;
}

.performance-date {
  background-color: $orange;
  text-align: center;
  padding: rem(10);
  box-sizing: border-box;
  @extend %font-size-6;
  font-weight: 100;
  font-size: 1.0rem;
  color: white;
  line-height: 2.2em;
  border-right: 1px solid #fff;

  div:nth-child(1) {
    color: white;
  }

  div:nth-child(2) {
    font-weight: $weight-bold;
    font-size: 3rem;
  }

  div:nth-child(3) {

  }

}

.performance-multiple-date {
  background-color: $orange;
  text-align: center;
  box-sizing: border-box;
  @extend %font-size-6;
  font-weight: 100;
  font-size: 0.8rem;
  color: white;
  line-height: 1.1em;
  border-right: 1px solid #fff;

  div:nth-child(1) {
    color: white;
    padding: rem(5);
  }

  div:nth-child(2) {
    font-weight: $weight-bold;
    font-size: 1.5rem;
  }

  div:nth-child(3) {
    color: white;
    padding: rem(5);
  }

}

.performance-info {
  display: inline-block;
  margin-left: rem(10);

  h3 {
    margin-bottom: rem(5);
  }

  p {
    @extend %font-size-6;
    font-weight: $weight-bold;
    margin-bottom: rem(10);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    @extend %font-size-6;
    font-weight: $weight-bold;

    li {
      display: block;
      float: left;
      margin-bottom: 0;
      margin-left: rem(5);
      padding-left: rem(5);
      color: $orange;

      &:first-child {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
      }

      a {
        color: $orange;
        text-decoration: none;
      }

      a:hover {
        color: $white;
      }
    }
  }
}

#description_label {
  margin-top: 10px;
}


.sortable-table th {
  cursor: pointer;
}


.tablesorter-headerAsc,
.tablesorter-headerDesc {
  font-weight: 600 !important;
}

th.tablesorter-headerAsc::after,
th.tablesorter-headerDesc::after,
th.tablesorter-headerUnSorted::after {
  font-family: FontAwesome;

  margin-left: rem(30);
  margin-top: -13px;
  display: inline-block;
  float: right;
}

th.tablesorter-headerUnSorted::after {
  content: $fa-var-sort;
}

th.tablesorter-headerAsc::after {
  content: $fa-var-caret-down;
}

th.tablesorter-headerDesc::after {
  content: $fa-var-caret-up;
}

th.sorter-false::after {
  content: none !important;
}

.selectable-table-td {
  display: table-cell !important;
  white-space: normal;
  vertical-align: middle;
}

// Table checkbox

.table-checkbox {
  position: absolute;
  left: -2.5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
}

.table-checkbox-header {
  position: absolute;
  left: -8.1rem;
}

.all-rows button {
  min-width: 35px !important;
  padding: 5px 5px !important;
  min-height: 35px !important;
}

.all-rows {
  border: 1px solid #ddd;
}


.table-with-favorites {
  th:first-child {
    padding-left: 3.3rem;
  }
}

.table-favorite {
  display: table-cell;
  vertical-align: middle;
  padding-left: 1rem;
  font-size: 1.5rem;
  width: 5rem;
}

.toggle-favorite {
  .fa-star {
    color: $primary-color;
  }
}

.table-level-section input{
  padding: 5px !important;
}

.table-level-section-rows button {
  margin-left: 1.5px !important;
}