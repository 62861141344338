#notes
{
    overflow: auto;
    max-height: 40vh;
}

.chat-message
{
    position: relative;
    display: table;
    margin: rem(40) 0 0 0;
    width: 100%;
    
    &:first-child
    {
        margin-top: 0px;
    }
}

.chat-avatar-container
{
    display: table-cell;
    width: 50px;
    vertical-align: middle;
}

.chat-avatar
{
    background-color: #333333;
    border-radius: 50%;
    display: block;
    width: 50px;
    height: 50px;
    margin-right: rem(30);
    
    img
    {
        border-radius: 50%;
        max-width: 100%;   
    }
}

.chat-text
{
    display: table-cell;
    vertical-align: middle;
    white-space: normal;
    
    h5
    {
       margin-bottom: 0; 
    }
    p
    {
        margin-top: 0;
        margin-bottom: 0;
        
    }
}

#chat-text-enter
{
    font-size: rem(14);
}