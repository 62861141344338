//––––––––––––––––––––––––––––––––––––––––––––––––––
// Base Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––

*, *:before, *:after {
    box-sizing: inherit;
}

html {
  font-size: 100%;
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  color: $font-dark;
  background-color: $light-background;

}

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid $light-grey;
}


pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol {
  margin-bottom: 1.5rem;
}


.href-link{
  color: $orange !important;
  text-decoration: none;
}

p.filled-field{
  padding-bottom: 2px;
  border-bottom: 2px dotted $mid-gray;
}

.comment-human img {
  border-radius: 75px;
  border: 3px solid #333;
}

.spacer-10 {
  min-height: 10px;
}

.spacer-20 {
  min-height: 20px;
}

.spacer-30 {
  min-height: 30px;
}

.spacer-40 {
  min-height: 40px;
}

.spacer-50 {
  height: 50px;
}

.spacer-60 {
  min-height: 60px;
}

.spacer-70 {
  min-height: 70px;
}

.spacer-80 {
  min-height: 80px;
}

.spacer-90 {
  min-height: 90px;
}
.spacer-100 {
  min-height: 100px;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.scale-spinner {
  color: $orange;
}
