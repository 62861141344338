.cursor-auto {

  cursor: auto;

}
.cursor-default {

  cursor: default;

}
.cursor-pointer {

  cursor: pointer;

}
.cursor-wait {

  cursor: wait;

}
.cursor-text {

  cursor: text;

}
.cursor-move {

  cursor: move;

}
.cursor-help {

  cursor: help;

}
.cursor-not-allowed {

  cursor: not-allowed;

}