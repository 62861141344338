.tabs {
  @extend %font-size-4; 
  
  display: inline-block;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: transparent;
  
  position: relative;
  
  border-bottom: 2px solid $mid-gray;
  
  margin-bottom: 10px;

  .tab {
    display: block;
    float: left;
    text-align: center;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    min-width: 240px;
    
	margin-bottom: 0;

    a {
      color: #222222;
      display: block;
      
      padding-left: rem(25);
	  padding-right: rem(25);
      padding-bottom: rem(15);
      
      height: 100%;
	  width: 100%;
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      //transition: color .28s ease;
      
      &:hover {
        color: lighten(#222222, 20%);
      }
    }

    &.disabled a {
      color: lighten(#222222, 20%);
      cursor: default;
    }
  }
  .indicator {
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: $orange;
    will-change: left, right;
  }
}