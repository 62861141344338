.pivot {
  width: 100%;
  overflow: auto;
}
.pvtAttr {
  color: #FFF;
  background-color: $primary-color !important;
  padding: 8px !important;
}
.pvtAxisLabel, .pvtColLabel, .pvtRowLabel {
  font-family: GTest,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.pvtVal {
  font-family: GTest,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 12px !important;
  font-weight: 100 !important;
}
.pvtTotalLabel {
  font-family: GTest,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 17px !important;
  font-weight: 300 !important;
}
.pvtGrandTotal {
  font-family: GTest,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}
.pvtTotal, .colTotal {
  font-family: GTest,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.pvtUi {
  color: #000000;
}

.pvtUiCell {
  background: #ffffff;
  border: 1px solid #000000;
  padding: 5px;
}

.pvtUiCell select {
  @extend .btn;
  width: max-content;
  align-content: start;
  font-size: 15px;

}
table.pvtTable {
  font-size: 8pt;
  text-align: left;
  border-collapse: collapse;
  tr {
    th {
      background-color: #ffffff;
      border: 1px solid #CDCDCD;
      font-size: 8pt;
      padding: 5px;
    }
    td {
      color: #000000;
      padding: 5px;
      background-color: #ffffff;
      border: 1px solid #CDCDCD;
      vertical-align: top;
      text-align: right;
    }
  }
  .pvtColLabel {
    text-align: center;
  }
  .pvtTotalLabel {
    text-align: right;
  }
}
.pvtTotal {
  font-weight: bold;
  color: #000000;
}
.pvtGrandTotal {
  font-weight: bold;
  color: #000000;
}
.pvtVals {
  color: #000000;
  border: 1px solid #000000;
  background: #ffffff;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
}
.pvtAggregator {
  margin-bottom: 5px;
  color: #000000;
}
.pvtAxisContainer {
  border: 1px solid #000000;
  background: #ffffff;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
  li {
    padding: 10px 8px;
    list-style-type: none;
    cursor: move;
    color: #000000;
    span.pvtAttr {
      @extend .shadow-md;
      -webkit-text-size-adjust: 100%;
      background: #ffffff;
      padding: 5px 5px;
      white-space: nowrap;
      color: #ffffff;
      font-size: 14px;
    }
  }
  li.pvtPlaceholder {
    -webkit-border-radius: 5px;
    padding: 3px 15px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px dashed #aaa;
    color: #000000;
  }
}
.pvtTriangle {
  cursor: pointer;
  color: #ff0000;
}
.pvtHorizList {
  li {
    display: inline;
  }
}
.pvtVertList {
  vertical-align: top;
  color: #000000;
}
.pvtFilteredAttribute {
  font-style: italic;
  color: #000000;
}
.pvtFilterBox {
  z-index: 100;
  width: 280px;
  border: 1px solid #000000;
  background-color: #ffffff;
  position: absolute;
  padding: 20px;
  text-align: center;
  h4 {
    margin: 0;
  }
  p {
    margin: 1em auto;
  }
  label {
    font-weight: normal;
  }
  input[type='checkbox'] {
    margin-right: 5px;
  }
  button {
    @extend .btn-small;
    width: max-content;
  }
}
.pvtCheckContainer {
  text-align: left;
  overflow: scroll;
  width: 100%;
  max-height: 200px;
  p {
    margin: 5px;
  }
}
.pvtRendererArea {
  padding: 5px;
  color: #000000;
  text-align: start;
}
.pvtFilter {
  visibility: visible !important;
  left: 0 !important;
  position: relative !important;
}
