
.ui-notification {
  font-size: 1.0rem;  
  box-shadow: none;
  float: right; 
  color: $font-dark;
  
  &.active
  {
    color: $orange; 
  }
  
  a {
    padding: rem(5);
    padding-top: 0;
  }
}

.notification-circle {
	@extend .circle-small;
	
    font-size: rem(12);
	position: relative;
	background-color: $orange;
	
	span {
		color: $white;
		font-weight: $weight-regular;
		display: inline-block;
		position: relative;
		left: 50%;
		top:50%;
		transform: translate(-50%,-50%);
	}
}

.notification-circle-alt-color{
  background: $dark-purple;
}

.ui-object {
	@extend .clearfix;
 
  width: rem(366);
	box-sizing: border-box;
  float: left;
	background-color: $white;
	display: block;
	padding: rem(30);
	border-radius: $global-radius;
	cursor: pointer;
	transition: $base-animation;
  position: relative;
  margin-bottom: rem(20);
   margin-left: rem(20);
  
	&:hover
	{
		@extend .z-depth-1;
	}
  
  p {
    margin-bottom: rem(0);
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &:last-child
    {
      margin-bottom: 0;
    }
  }
}

.ui-object-small
{
  @extend .ui-object;
  width: rem(270);
  padding-top: rem(20);
}

.ui-thumb
{
  min-width: 100%;
  height: rem(200);
  margin-bottom: rem(15);
   position: relative;
   
   svg 
   {
         max-height: 100%;
         max-width: 100%; 
         position: absolute;
         width: 100%;
   }
   
}

.ui-spacer
{
    margin-bottom: 160px;
}
.svg-placeholder
{
    min-height: rem(200);
    width: 100%;
    background-color: #f0f0f0;
    
    p 
    {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.ui-thumb-circle
{
  @extend .circle;
  
  display: block;
  width: rem(135);
  height:rem(135);
  margin: auto;
  
  margin-top: rem(20);
  margin-bottom: rem(15);
}

.ui-object-row
{
    margin-left: -(rem(20));
}

.ui-spacer
{
    display: block;
    margin-bottom: 160px;
}

/*************************************
                 Card
**************************************/
.card-container
{
    
}

/* entire container, keeps perspective */
.card {
    height: rem(484);
    margin-bottom: 2%;
            
   
   padding: 0;
   background-color: transparent;
}
    .card:hover
    {
          box-shadow: none;
    }
    .card:first-child
    {
        margin-left: 0;
    }

	/* flip the pane when hovered */
	.card.flip .card-face {
		transform: rotateY(180deg);
	}
    @media (min-width:1300px) {
        .card
        {
            width: 32%;
            margin-left: 2%;
            
        }
        .card:nth-child(3n+4)
        {
            margin-left: 0;
        }   
    }
    @media (max-width:1299px) and (min-width:451px)
    {
        .card
        {
            width: 49%;
            margin-left: 2%;
            
        }
        .card:nth-child(2n+3)
        {
            margin-left: 0;
        }   
        
    }
    @media (max-width:450px) 
    {
       .card
        {
            width: 100%;
            margin-left: 0;
            
        }
        
    }

/* flip speed goes here */
.card-face {
	transition: 0.2s;
	transform-style: preserve-3d;

	position: relative;
    
    width: 100%;
    height: 100%;
}

.card-inner {
    overflow: auto;
    position: absolute;
    bottom: rem(70);
    top: 0;
    left: 0;
    padding: rem(30);
    padding-bottom: 0;
    right: 0;    
}

/* hide back of pane during swap */
.card-front, .card-back {
	backface-visibility: hidden;

	position: absolute;
	top: 0;
	left: 0;
    bottom:0;
    right: 0;
    padding: rem(30);
    
}

/* front pane, placed above back */
.card-front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
    background-color: #ffffff;
}

/* back, initially hidden pane */
.card-back {
	transform: rotateY(180deg);
        overflow-y: auto;
        background-color: #f6f6f6;
}

.card-front:hover, .card-back:hover{
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        
}

.card-thumb-small
{
    height: rem(100);
}

.flip-card
{
    padding: 1.5rem 1.875rem;
    position: absolute;
    right: 0;
    bottom: 0;
}

.card-new
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); 
    
    h3
    {
        color: #222222;
    }
    
}

.card-selected
{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $orange;
    color: white;
    z-index: 1000;
    display: none;
    opacity: 0;
    transition: all .2s ease;
    
    .fa-check
    {
        font-size: 5rem;
        color: #ffffff;
        position: absolute;
        top: 50%;
        opacity: 1;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
}
.actionable {
  position: absolute;
  top: 2px;
  left: 2px;
  color: $orange
}
.user-pict {
  height: 35px;
  width: 35px;
  margin: -10px 10px -10px 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}