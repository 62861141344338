//––––––––––––––––––––––––––––––––––––––––––––––––––
// Typography
//––––––––––––––––––––––––––––––––––––––––––––––––––
%font-antialiasing
{
  -moz-osx-font-smoothing: grayscale;
  font-smooth:always;
  -webkit-font-smoothing: antialiased;
}

%heading-base {
  @extend %font-antialiasing;
  margin-top: 0;
  margin-bottom: 1em;
  font-weight: 300;
  font-family: $font-family;
}

%font-size-1 {
  font-size: $font-size-1; 
  letter-spacing: -0.06rem;  
}

%font-size-2 {
  font-size: $font-size-2; 
  letter-spacing: 0rem;  
}

%font-size-3 {
  font-size: $font-size-3; 
  letter-spacing: 0rem; 
  line-height: 1.6rem;
}

%font-size-4 {
  font-size: $font-size-4; 
  letter-spacing: -.0.06rem;  
  
}

%font-size-5 {
  font-size: $font-size-5; 
  letter-spacing: 0rem; 
  font-weight: 400;
  line-height: 1.2rem;
}

%font-size-6 {
  font-size: $font-size-6; 
  text-transform: uppercase;   
  font-weight: 400;  
}

h1 { 
  @extend %heading-base; 
  @extend %font-size-1; 
  @extend .clearfix;
  margin-top: rem(10);
}

h2 { 
  margin-top: rem(40);
  @extend %heading-base; 
  @extend %font-size-2; 
  @extend .clearfix;
}

h3 { 
  @extend %heading-base; 
  @extend %font-size-3;
 // @extend .clearfix; 
}

h4 { 
  @extend %heading-base; 
  @extend %font-size-4;
  line-height: 1.4rem;
  @extend .clearfix; 
}
 
h5 { 
  @extend %heading-base; 
  @extend %font-size-5;
  @extend .clearfix; 
}
  
h6 { 
  @extend %heading-base; 
  @extend %font-size-6;
  @extend .clearfix; 
}

p {
  @extend %font-size-5;
  margin-top: 0;
  line-height: 1.6;
}

@font-face {
  font-family: 'GTest';
  src: url('../../fonts/gt-book.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GTest';
  src: url('../../fonts/gt-bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GTest';
  src: url('../../fonts/gt-light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GTest';
  src: url('../../fonts/gt-medium.otf');
  font-weight: 600;
  font-style: normal;
}