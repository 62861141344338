@charset "UTF-8";
/*
* TiX.By V0.5
* www.tix.by
* 
* Master SCSS file for TiX.By
*
*/

/* Normalize & Reset */
@import "base/normalize";

/* Variables & Functions */
@import "base/prefixers";
@import "base/variables";
@import "base/utils";
@import "base/functions";
@import "tailwind/cursor.css";
@import "tailwind/max.css";
@import "tailwind/border.css";
@import "tailwind/text.css";
@import "tailwind/font.css";
@import "tailwind/p.css";
@import "tailwind/py.css";
@import "tailwind/px.css";

/* Base */
@import "base/fontawesome/font-awesome";
@import "base/generics";
@import "base/typography";
@import "base/waves";

/* Partials */
@import "partials/grid";
@import "partials/buttons";
@import "partials/dropdowns";
@import "partials/forms";
@import "partials/lists";
@import "partials/tables";
@import "partials/uimisc";
@import "partials/tabs";
@import "partials/statuses";
@import "partials/modal";
@import "partials/chat";
@import "partials/pivot";
@import "partials/alerts";
@import "components/collapsible";
@import "partials/alerts";
@import "partials/notifications";

/* Sections */
@import "components/collapsible";
@import "components/header";
@import "components/app-main";
@import "components/map";
@import "components/signin";
@import "components/landing";
@import "components/settings";
@import "components/contacts";
@import "components/notes";

/* Misc */
@import "components/datetimepicker/datetimepicker";
@import "vendor/twittertypeahead";
@import "vendor/pnotify";
@import "vendor/fileinput";
@import "vendor/sweetalert";
@import "vendor/tixpop";
@import "vendor/editable";

@import "base/print";

/* Tailwind */
@import "tailwindcss/src/css/preflight.css";

