.container {
  position: relative;
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.col {
  width: 100%;
  float: left;
  box-sizing: border-box;
}

// For devices larger than 400px
@media (#{$bp-larger-than-mobile}) {
  .container {
    width: $container-width-larger-than-mobile;
    padding: 0;
  }
}

// For devices larger than 550px
@media (#{$bp-larger-than-phablet}) {
  .container {
    width: $container-width-larger-than-phablet;
  }

  .col {
    margin-left: $column-margin;
  }

  .col:first-child {
    margin-left: 0;
  }

  .col-1.col          { width: grid-column-width(1);  }
  .col-2.col          { width: grid-column-width(2);  }
  .col-3.col       { width: grid-column-width(3);  }
  .col-4.col        { width: grid-column-width(4);  }
  .col-5.col        { width: grid-column-width(5);  }
  .col-6.col          { width: grid-column-width(6);  }
  .col-7.col       { width: grid-column-width(7);  }
  .col-8.col        { width: grid-column-width(8);  }
  .col-9.col         { width: grid-column-width(9);  }
  .col-10.col          { width: grid-column-width(10); }
  .col-11.col       { width: grid-column-width(11); }
  .col-12.col       { width: 100%; margin-left: 0;  }

  .one-third.col     { width: grid-column-width(4);  }
  .two-thirds.col    { width: grid-column-width(8);  }

  .one-half.col      { width: grid-column-width(6);  }


  // Offsets
  .offset-by-one.col       { margin-left: grid-offset-length(1);  }

  .offset-by-two.col       { margin-left: grid-offset-length(2);  }

  .offset-by-three.col     { margin-left: grid-offset-length(3);  }

  .offset-by-four.col      { margin-left: grid-offset-length(4);  }

  .offset-by-five.col      { margin-left: grid-offset-length(5);  }

  .offset-by-six.col       { margin-left: grid-offset-length(6);  }

  .offset-by-seven.col     { margin-left: grid-offset-length(7);  }

  .offset-by-eight.col     { margin-left: grid-offset-length(8);  }

  .offset-by-nine.col      { margin-left: grid-offset-length(9);  }

  .offset-by-ten.col       { margin-left: grid-offset-length(10); }

  .offset-by-eleven.col    { margin-left: grid-offset-length(11); }


  
  .offset-by-one-third.col  { margin-left: grid-offset-length(4);  }
  
  .offset-by-two-thirds.col { margin-left: grid-offset-length(8);  }

  .offset-by-one-half.column,
  .offset-by-one-half.column   { margin-left: grid-offset-length(6);  }


}

// Clearing
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Self Clearing Goodness

.container:after,
.row:after,
.u-cf {
  content: "";
  display: table;
  clear: both;
}
