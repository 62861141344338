// Variables

$zindex-popover:           2000 !default;
$zindex-tooltip:           2010 !default;

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           200px !default;
//** Tooltip text color
$tooltip-color:               #fff !default;
//** Tooltip background color
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;

//** Tooltip arrow width
$tooltip-arrow-width:         5px !default;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg !default;


//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          #fff !default;
//** Popover maximum width
$popover-max-width:                   500px !default;
//** Popover border color
$popover-border-color:                none;
//** Popover fallback border color
$popover-fallback-border-color:       #fff !default;

//** Popover title background color
$popover-title-bg:                    #fff !default;

//** Popover arrow width
$popover-arrow-width:                 10px !default;
//** Popover arrow color
$popover-arrow-color:                 $popover-bg !default;

//** Popover outer arrow width
$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
//** Popover outer arrow color
$popover-arrow-outer-color:           none;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color:  none;

//
// Tooltips
// --------------------------------------------------
// Base animation

.fade {
  opacity: 0;
  //transform: scale(.1);
  transition: opacity .2s ease;
  &.in {
    opacity: 1;
    transform: scale(1);
  }
}


// Base class
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  font-size: 1rem;

  opacity: 0;

  &.in     { opacity: .9; }
  &.top    { margin-top:  -3px; padding: $tooltip-arrow-width 0; }
  &.right  { margin-left:  3px; padding: 0 $tooltip-arrow-width; }
  &.bottom { margin-top:   3px; padding: $tooltip-arrow-width 0; }
  &.left   { margin-left: -3px; padding: 0 $tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: 3px 8px;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  border-radius: 4px;
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.top-left .tooltip-arrow {
    bottom: 0;
    right: $tooltip-arrow-width;
    margin-bottom: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: $tooltip-arrow-width;
    margin-bottom: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    border-right-color: $tooltip-arrow-color;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-left-color: $tooltip-arrow-color;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
}


//
// Popovers
// --------------------------------------------------


.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: none;
  max-width: $popover-max-width;
  padding: 1px;
  text-align: left;
  // Our parent element can be arbitrary since popovers are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.

  font-size: 1rem;

  background-color: $popover-bg;
  background-clip: padding-box;
  border-radius: 4px;
  @extend .z-depth-2;

  // Offset the popover to account for the popover arrow
  &.top     { margin-top: -$popover-arrow-width; }
  &.right   { margin-left: $popover-arrow-width; }
  &.bottom  { margin-top: $popover-arrow-width; }
  &.left    { margin-left: -$popover-arrow-width; }
}

.popover-title {
  margin: 0; // reset heading margin
  padding: 1.5rem;
  padding-bottom: 0rem;
  margin-bottom: -.5rem;
  background-color: $popover-title-bg;
  text-align: left;
}

.popover-content {
   padding: 1.5rem;
   text-align: left;
}

// Arrows
//
// .arrow is outer, .arrow:after is inner

.popover > .arrow {
  &,
  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
}
.popover > .arrow {
  border-width: $popover-arrow-outer-width;
}
.popover > .arrow:after {
  border-width: $popover-arrow-width;
  content: "";
}

.popover {
  &.top > .arrow {
    left: 50%;
    margin-left: -$popover-arrow-outer-width;
    border-bottom-width: 0;
    border-top-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-top-color: $popover-arrow-outer-color;
    bottom: -$popover-arrow-outer-width;
    &:after {
      content: " ";
      bottom: 1px;
      margin-left: -$popover-arrow-width;
      border-bottom-width: 0;
      border-top-color: $popover-arrow-color;
    }
  }
  &.right > .arrow {
    top: 50%;
    left: -$popover-arrow-outer-width;
    margin-top: -$popover-arrow-outer-width;
    border-left-width: 0;
    border-right-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-right-color: $popover-arrow-outer-color;
    &:after {
      content: " ";
      left: 1px;
      bottom: -$popover-arrow-width;
      border-left-width: 0;
      border-right-color: $popover-arrow-color;
    }
  }
  &.bottom > .arrow {
    left: 50%;
    margin-left: -$popover-arrow-outer-width;
    border-top-width: 0;
    border-bottom-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-bottom-color: $popover-arrow-outer-color;
    top: -$popover-arrow-outer-width;
    &:after {
      content: " ";
      top: 1px;
      margin-left: -$popover-arrow-width;
      border-top-width: 0;
      border-bottom-color: $popover-arrow-color;
    }
  }

  &.left > .arrow {
    top: 50%;
    right: -$popover-arrow-outer-width;
    margin-top: -$popover-arrow-outer-width;
    border-right-width: 0;
    border-left-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-left-color: $popover-arrow-outer-color;
    &:after {
      content: " ";
      right: 1px;
      border-right-width: 0;
      border-left-color: $popover-arrow-color;
      bottom: -$popover-arrow-width;
    }
  }
}
.popover-content ul
{
    margin-left: rem(20);
}