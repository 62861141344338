.print-visible-statement {
  display: none !important;
}

.print-visible
{
  display: none;
}
.print-visible-text
{
  display: none;
  position: relative;
  top: 20px;
}
.statement-table {
  border: 1px solid #666;
  //td {
  //  height: 45px;
  //}
}
.print-visible-div {
  //display: none;
}
.print-statement-title {
  text-decoration: underline;
  margin-bottom: 5px;
}

.dividing-line {
  margin-top: 0;
  margin-bottom: 0;
  border-width: 0px;
  border-top: 3px solid #666;
}

@media print {

    .container
    {
        width: 100% !important;
        max-width: auto;
    }

    html
    {
        font-size:70%;
    }
    
    .map
    {
        max-height: 3.5in;
        margin: auto !important;
    }
    
   .app-header
   {
       display: none !important;
   }
   
   .btn
   {
       display: none !important;
   }
   
   .section_content
   {
       display: block !important;
   }
   
   .content_toggles
   {
       display: none !important;
   }
   
   th::after 
   {
      content: "" !important;
      display: none !important;
   }
   
   h2
   {
       margin-top: 2rem;
       margin-bottom: 1rem;
   }
   
   h2.print-scale
   {
       margin-bottom: 1rem !important;
       margin-top:0 !important;
   }
   
   .app-main
   {
       margin-bottom: 0px !important;
   }
   
   .print-scale
   {       
        .col-4
        {
            display: none !important;
        }
        
        .col-8
        {
            width: 100% !important;
        }
   }
   
   .regular-table tbody tr:nth-child(even) {
       -webkit-print-color-adjust: exact;
        background: #EEEEEE !important;
    }
   
   .pl-circle-scale
   {
       -webkit-print-color-adjust: exact;
       width: 20px;
       height: 20px;
   }
   
   th, td
   {
       padding: 5px !important;
   }
   
   .map text
   {
       font-weight: 400 !important;
   }
   
   .ed-hide
   {
       display: none;
   }

   .print-visible
   {
        display: block !important;
        position: fixed;
        right: 0;
        top: 0;
        /* height: 20px; */
        text-align: right;

        img
        {
            width: 100px;
        }
   }
  .print-visible-statement
  {
    display: block !important;
    position: fixed;
    left: 0;
    top: 0;
    /* height: 20px; */
    text-align: left;

    img
    {
      width: 100px;
    }
  }
  .print-visible-text {
    display: block !important;
  }
  .print-visible-div {
    display: block !important;
  }

   body
   {
       background-color: #ffffff !important;
   }
   
   .editable
   {
       border: none;
       font-weight: $weight-bold;
   }
   
   .svg-table th:nth-child(5) {
        display: none !important;
    }
    .svg-table td:nth-child(5) {
        display: none !important;
    }

  .view-contact-container{
    margin-top: 75px !important;
  }
}

  @page {
        margin: 5%;
    }
    
    
  