.p-0 {

  padding: 0px;

}
.p-1 {

  padding: 0.25rem;

}
.p-2 {

  padding: 0.5rem;

}
.p-3 {

  padding: 0.75rem;

}
.p-4 {

  padding: 1rem;

}
.p-5 {

  padding: 1.25rem;

}
.p-6 {

  padding: 1.5rem;

}
.p-7 {

  padding: 1.75rem;

}
.p-8 {

  padding: 2rem;

}
.p-9 {

  padding: 2.25rem;

}
.p-10 {

  padding: 2.5rem;

}
.p-11 {

  padding: 2.75rem;

}
.p-12 {

  padding: 3rem;

}
.p-14 {

  padding: 3.5rem;

}
.p-16 {

  padding: 4rem;

}
.p-20 {

  padding: 5rem;

}
.p-24 {

  padding: 6rem;

}
.p-28 {

  padding: 7rem;

}
.p-32 {

  padding: 8rem;

}
.p-36 {

  padding: 9rem;

}
.p-40 {

  padding: 10rem;

}
.p-44 {

  padding: 11rem;

}
.p-48 {

  padding: 12rem;

}
.p-52 {

  padding: 13rem;

}
.p-56 {

  padding: 14rem;

}
.p-60 {

  padding: 15rem;

}
.p-64 {

  padding: 16rem;

}
.p-72 {

  padding: 18rem;

}
.p-80 {

  padding: 20rem;

}
.p-96 {

  padding: 24rem;

}
.p-px {

  padding: 1px;

}