.app-header {
  @extend %font-antialiasing;
  //@extend .z-depth-2;
  padding-top: rem(10);
  padding-bottom: 0px;
  background-color: $dark-gray;
  color: $font-light;
  width: 100%;
  font-weight: $weight-light;
  //transition: $base-animation;


  .app-header-top-nav {
    @extend %font-size-4;
    @extend .clearfix;
    padding-bottom: rem(5);

    & .pull-right li {
      margin-left: rem(5);
    }

    li {
      margin-left: 3rem;
      margin-bottom: rem(5);

      & a {
        padding: rem(10);
      }

      &.active {
        font-weight: $weight-bold;

        a {
          color: $font-light;
        }
      }
    }

    .ui-dropdown li a {
      color: #222222;
    }

    .global-nav li:first-child {
      margin-left: 0;

      & a {
        padding-left: 0;
      }
    }
  }

  .app-header-mid {
    position: relative;
    left: -(rem(3));

    h1 span {
      opacity: .50;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 38px;
      opacity: .75;
    }

    h1.header-with-sub {
      margin-bottom: 10px;
    }

    .header-placeholder {
      position: absolute;
      left: (-3 - remClean(25)) + rem;
      width: 3rem;
      height: 3rem;
      font-size: 2.5rem;
      text-align: center;
      //border: white dashed 1px;

      .performance-date {
        width: 3rem;
        height: 3rem;
        background-color: $primary-color;
        text-align: center;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 0.5rem;
        color: #fff;
        line-height: 1rem;
        padding: 0 0 0;
        position: relative;

        div:nth-child(1) {
          color: white;
        }

        div:nth-child(2) {
          font-weight: $weight-bold;
          font-size: 1.2rem;
        }

        div:nth-child(3) {

        }

      }

      .performance-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .app-header-bottom-nav {
    position: relative;
    @extend %font-size-6;
    font-weight: $weight-light;

    li {
      margin-left: rem(20);
      margin-bottom: 0;
      height: rem(37);
      position: relative;

      a {
        padding-left: rem(25);
        padding-right: rem(25);
        height: 100%;
        width: 100%;
      }

      &:first-child {
        margin-left: 0;
      }

      & a::after {
        content: "";
        background-color: $orange;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5px;
        width: 100%;
        transform: scaleX(0);
        //transition: $base-animation;
      }

      &.active a {
        color: $font-light;

        &::after {
          transform: scaleX(1);
        }
      }

      &:hover a {
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }

  a {
    text-decoration: none;
    color: $font-gray-on-dark;
    //transition: $base-animation;
    box-sizing: border-box;
    display: inline-block;

    &:hover {
      color: $font-light;

    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      float: left;
    }
  }
}

.app-header {
  .notification-circle {

    top: -(rem(12));
    left: rem(15);

    span {

      left: 48% !important;
      top: 48% !important;

    }
  }

}

.profile-icon {
  @extend .circle-small;
  position: relative;
  top: -(rem(3));

  float: left;

  & img {
    @extend .circle;
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.notifications-icon {
  font-size: 1.4em;
  position: relative;
  top: -4px;
}