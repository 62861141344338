.setting-item{
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 1.25rem;
	background-color: #ffffff;
	display: block;
	padding: 1.875rem;
	position: relative;
	cursor: pointer;
	//transition: all 200ms ease;
	border-left: 6px solid #666666;
}

.setting-item a{
	color: #333333;
	text-decoration: none;
}


.setting-item a.text-primary{
	color: blue;
	text-decoration: underline;
}
.setting-item a.text-primary:hover{
	text-decoration: underline;
}


.setting-table-head{
	margin-left: 6px;
	display: inline-block;
	margin-bottom: 10px;
	font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 400;
}

.first-setting-head{
	margin-left: 36px;
}