//––––––––––––––––––––––––––––––––––––––––––––––––––
// Dropdowns
//––––––––––––––––––––––––––––––––––––––––––––––––––

%dropdown-base
{
  display: inline-block;
  position: relative;
  
  & a
  {
    color: $font-dark;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  & ul
  {
    text-decoration: none;
    list-style: none;
  }  
}

.ui-dropdown
{
  @extend %dropdown-base;
  
  & > a::after
  {
    font-family: FontAwesome;
    margin-left: rem(10);
    display: inline-block;
    content: $fa-var-caret-down;
  }
}

.ui-toggle
{
    color: $font-dark;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

  &::after
  {
    font-family: FontAwesome;
    margin-left: rem(10);
    display: inline-block;
    content: $fa-var-caret-down;
  }  
  
  &.active
  {
    font-weight: $weight-bold;
  }
  &.up::after
  {
    font-family: FontAwesome;
    margin-left: rem(10);
    display: inline-block;
    content: $fa-var-caret-up;    
  }
}

.ui-dropdown-small {
  @extend %font-size-6;
  
  margin-left: rem(20);
  margin-right: rem(20);
  
  a {
    padding: rem(10);
    padding-top: rem(13);
  }
}

.ui-object-small, .ui-object 
{
    .ui-more {
        
        z-index: 100;
        
        a {
            padding: none;
            display:inline-block;
        }
    }
}

.ui-more {
  @extend %dropdown-base;
  font-size: 1.5rem;  
  box-shadow: none; 
  
  a {
    display: inline-block;
    padding: rem(8);
  }
}

// Dropdown



.dropdown-content {
  @extend .z-depth-1;
  background-color: $white;
  margin: 0px !important;
  display: none;
  min-width: rem(100);
  max-height: rem(650);
  overflow-y: auto;
  opacity: 0;
  position: absolute;
  white-space: normal;
  z-index:  1000;
  will-change: width, height;

  li.group
  {
      a {
          color: $orange;
          font-weight: $weight-bold;
          padding-left:rem(10) !important;
      }
      &:hover
      {
          background-color: #ffffff;
      }
      
      & ~ li
      {
          a {
              padding-left: rem(20);
          }
      }
  }
 

  li {
    clear: both;
    color: $font-dark;
    cursor: pointer;
    line-height: 1.5rem;
    width: 100%;
    text-align: left;
    text-transform: none;
    margin: 0 !important;
    list-style-type: none;
    box-sizing: border-box;

    &:hover, &.active, &.selected {
      background-color: $dropdown-hover-bg-color;
    
      
    }

    &.active.selected {
      background-color: darken($dropdown-hover-bg-color, 5%);
    }

    &.divider {
      min-height: 0;
      height: 1px;
      background-color: #e0e0e0;
    }

    & > a, & > span {
      @extend %font-size-6;
      
      color: $font-dark;
      display: block;
      line-height: rem(22);
      padding: rem(10);
    } 

    & > span > label {
      top: rem(1);
      left: rem(3);
      height: rem(18);
    }

    // Icon alignment override
    & > a > i {
      height: inherit;
      line-height: inherit;
    }
  }
}

.dropdown-content-large
{
    min-width: rem(300);
}