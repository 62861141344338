.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {    /* used to be tt-dropdown-menu in older versions */
  width: 450px;
  margin-top: 2px;
  padding: 0;
  background-color: #fff;
  border-radius: $global-radius;
  @extend .z-depth-1;
  font-size: 1rem;
  z-index: 10000 !important;
}

.tt-suggestion {
  padding: 15px;
  line-height: 24px;
}

.tt-suggestion.tt-cursor,.tt-suggestion:hover {
  color: #fff;
  background-color: $orange;

}

.tt-suggestion p {
  margin: 0;
}

.tt-selectable
{
    cursor: pointer;
}

.twitter-typeahead
{
    width: 100%;
    
    input[type=text]
    {
        margin-bottom: 0px;
        padding-right: 3rem;
    }
    
}

.search-field
{
    margin-top: 0px;
}