/*

------------------
 Old collapsible
------------------

$collapsible-height: 3rem !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;

$sidenav-font-color: #444 !default;
$sidenav-bg-color: #fff !default;
$sidenav-padding-right: 30px !default;
$sidenav-item-height: 64px !default;

.collapsible {

  list-style: none;

  li
  {
       @extend .z-depth-1;
       margin-bottom: rem(20);
  }
}

.collapsible-header {
  display: block;
  cursor: pointer;
  min-height: $collapsible-height;
  line-height: $collapsible-height;
  padding: 0 1rem;
  background-color: $collapsible-header-color;
}

.collapsible-body {
  display: none;
  box-sizing: border-box;
  background-color: white;

  p {
    margin: 0;
    padding: 2rem;
  }
}

*/

.collapsible 
{
    border-collapse: collapse;
}

.collapsible-table
{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: rem(10);
    //transition: all .3s ease;
    
    tbody + tbody::before
    {
    content: ' ';
    display: block;
    height: 15px;

    }
    
    thead tr
    {
        background: transparent;
    }
    
    tbody tr
    {
        background: white;
        //transition: all .3s ease;
    
    }

    td
    {
        position: relative;
        //transition: all .3s ease;
        @extend %font-size-5;
        background-color: transparent;
        border-bottom: none;
        padding: rem(15);
        
        &:first-child, &:last-child
        {
            padding: rem(15);
        }
        
        &.collapsed
        {
            padding: 0px rem(15);
        }
        &.hidden
        {
            display: none;
        }
        
    }

    th {
        @extend %font-size-6;
        border-bottom: none;    
        white-space: nowrap;
        background: transparent;
        
        
    }
    
    
}