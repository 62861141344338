//––––––––––––––––––––––––––––––––––––––––––––––––––
// Variables
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Breakpoints
$bp-larger-than-mobile    : "min-width: 400px" !default;
$bp-larger-than-phablet   : "min-width: 550px" !default;
$bp-larger-than-tablet    : "min-width: 750px" !default;
$bp-larger-than-desktop   : "min-width: 1000px" !default;
$bp-larger-than-desktophd : "min-width: 1200px" !default;

// Colors
$black: #000000 !default;
$white: #ffffff !default;
$gray-on-dark: #acb1be !default;

$dark-purple: #2e0927 !default;
$alt_orange: #ff8c00 !default;
$green: #bedb39 !default;
$yellow: #ffe11a !default;
$salmon: #ff9583 !default;
$blue:#0b6187 !default;
$gray: #666666 !default;
$dark-gray: #333 !default;
$mid-gray: #e1e1e1;
//$lighter-blue: #227AB4 !default;
$lighter-blue: #0099a8 !default;
//$orange: #ee293e !default;
$orange: $lighter-blue !default;

$button-primary-color: $orange;
$btn-primary-color: $orange;
$btn-primary-bg: $orange;

$btn-hover-bg: lighten($btn-primary-bg, 5%);

$button-secondary-color: $yellow;
$button-disabled:$gray;

$light-grey: #e1e1e1 !default;

$dark-grey: #333 !default;
$primary-color: $orange !default;
$secondary-color: lighten($dark-grey, 13.5%) !default;
$border-color: #bbb !default;
$link-color: $orange !default;

$light-background: #EAEFEF !default;

$font-dark: #333333 !default;
$font-light: #ffffff !default; 
$font-light-gray: $gray-on-dark !default;
$font-gray-on-dark: $gray-on-dark !default;

$status-green: $green;
$status-yellow: $yellow;
$status-red: $salmon;
$status-gray:$gray;
$status-orange: $alt_orange;

// Typography
$font-family: "GTest", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-size-1: rem(40); // Used on main page title
$font-size-2: rem(24); // Used on inner-content title
$font-size-3: rem(20); // Used as item titles for inner-content
$font-size-4: rem(16); // Used in header section titles
$font-size-5: rem(14); // Used in most body text
$font-size-6: rem(12); // Used as table and tab title

$weight-light: 300;
$weight-regular: 400;
$weight-bold: 600;

//Grid Variables
$container-width: 1140px !default;
$container-width-larger-than-mobile: 85% !default;
$container-width-larger-than-phablet: 80% !default;
$total-columns: 12 !default;
$column-width: 100 / $total-columns !default; // calculates individual column width based off of # of columns
$column-margin: 4% !default; // space between columns

// Animations
$base-animation: all 200ms ease !default;

// Misc
$global-radius: 4px !default;


// Dropdown
$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-item-height: 50px !default;

/*** Buttons ***/
$button-bg-color-disabled: #DFDFDF !default;
$button-color: $orange !default;
$button-color-disabled: #9F9F9F !default;
$button-color-flat: #343434 !default;
$button-color-raised: #fff !default;
$button-floating-size: 37px !default;
$button-height: 36px !default;
$button-font-size-shared: 1.3rem !default;
$button-large-icon-font-size: 1.6rem !default;
$button-line-height: 36px !default;


/*** Global ***/
// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;


/*** Forms ***/
// Text Inputs + Textarea
$input-border-color: $dark-gray !default;
$input-bg-color: #fff !default;
$input-error-color: $salmon !default;
$input-success-color: $green !default;
$input-focus-color: $orange !default;
$label-font-size: $font-size-6;
$input-disabled-color: rgba(0,0,0, .26) !default;
$input-disabled-solid-color: #BDBDBD !default;

// Radio Buttons
$radio-fill-color: $orange !default;
$radio-empty-color: #5a5a5a !default;

// Switches
$switch-bg-color: $orange !default;
$switch-checked-lever-bg: desaturate(lighten($orange, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: #818181 !default;