#notifications{
  position: absolute;
  min-width: 400px;
  background: white;
  color: black;
  margin-right: 10px;
  display: none;
  top: 50px !important;
  right: 50px !important;
  z-index: 1002;

}

.notification-item a,
.notif-top a{
  color: #222222 !important;
}

.notification-item a:hover,
.notif-top a:hover{
  color: $orange !important;
}

.notification-item:hover{
  background: $light-grey;
}

.notif-top {
  border-bottom: 1px #e0e0e0 solid;
  text-align: right
}

.unread {
  color: #ee293e;
}

.new-notification a {
  background-color: $light-grey;
  color: $orange !important;
  width: 100%
}
.new-notification a:hover {
  color: $orange !important;
  background: $white;
}
