.app-event-information {
	.status-passed
	{
		color: $font-light-gray;
	}
}

tr.status-passed
{
    .performance-date {
        background-color: $gray-on-dark;   
    }
    
    &:hover{
        background-color: $gray-on-dark !important;
        
        .performance-info ul li
        {
        color: $white;
        }
        
        & i {
        color: $white;
        }
        
        .notification-circle
        {
            background-color: #ffffff !important;
            
            span
            {
                color: #333333 !important;
            }
        }
        }    
}

.app-event {
	
	&.status-yellow{
		border-color: $status-yellow;
	}
	
	&.status-red{
		border-color: $status-red;
	}
	
	&.status-green{
		border-color: $status-green;
	}
	
	&.status-live {
		border-color: $status-green;
		
		& .status-graph-point:nth-child(4) div {
			background-color: $status-green;
			border-color: $status-green;
		}
	}
	&.status-pending-ticketing {
		border-color: $status-orange;
		
		& .status-graph-point:nth-child(3) div{
			background-color: $status-orange;
			border-color: $status-orange;
		}		
	}
	&.status-pending-box-office{
		border-color: $status-orange;
		
		& .status-graph-point:nth-child(2) div{
			background-color: $status-orange;
			border-color: $status-orange;
		}		
	}
	&.status-rejected{
		border-color: $status-red;

		& .status-graph-point:nth-child(2) div{
			background-color: $status-red;
			border-color: $status-red;
		}
	}
	&.status-draft {
		border-color: $status-gray;
		
		& .status-graph-point:nth-child(1) div{
			background-color: $status-gray;
			border-color: $status-gray;
		}		
	}
}

.non-editable
{
    .app-event-lock
    {
        display: inline-block !important;
    }
    
    &.app-event
    {
        cursor: auto !important;
        
        &:hover 
        {
            box-shadow: none !important;
        }
    }
}

.status-indicator {
	&.status-yellow div{
		background-color: $status-yellow;
		border-color: $status-yellow;
	}
	&.status-red div{
		background-color: $status-red;
		border-color: $status-red;
	}
	&.status-green div {
		background-color: $status-green;
		border-color: $status-green;
	
	}	
}