.px-0 {

  padding-left: 0px;
  padding-right: 0px;

}
.px-1 {

  padding-left: 0.25rem;
  padding-right: 0.25rem;

}
.px-2 {

  padding-left: 0.5rem;
  padding-right: 0.5rem;

}
.px-3 {

  padding-left: 0.75rem;
  padding-right: 0.75rem;

}
.px-4 {

  padding-left: 1rem;
  padding-right: 1rem;

}
.px-5 {

  padding-left: 1.25rem;
  padding-right: 1.25rem;

}
.px-6 {

  padding-left: 1.5rem;
  padding-right: 1.5rem;

}
.px-7 {

  padding-left: 1.75rem;
  padding-right: 1.75rem;

}
.px-8 {

  padding-left: 2rem;
  padding-right: 2rem;

}
.px-9 {

  padding-left: 2.25rem;
  padding-right: 2.25rem;

}
.px-10 {

  padding-left: 2.5rem;
  padding-right: 2.5rem;

}
.px-11 {

  padding-left: 2.75rem;
  padding-right: 2.75rem;

}
.px-12 {

  padding-left: 3rem;
  padding-right: 3rem;

}
.px-14 {

  padding-left: 3.5rem;
  padding-right: 3.5rem;

}
.px-16 {

  padding-left: 4rem;
  padding-right: 4rem;

}
.px-20 {

  padding-left: 5rem;
  padding-right: 5rem;

}
.px-24 {

  padding-left: 6rem;
  padding-right: 6rem;

}
.px-28 {

  padding-left: 7rem;
  padding-right: 7rem;

}
.px-32 {

  padding-left: 8rem;
  padding-right: 8rem;

}
.px-36 {

  padding-left: 9rem;
  padding-right: 9rem;

}
.px-40 {

  padding-left: 10rem;
  padding-right: 10rem;

}
.px-44 {

  padding-left: 11rem;
  padding-right: 11rem;

}
.px-48 {

  padding-left: 12rem;
  padding-right: 12rem;

}
.px-52 {

  padding-left: 13rem;
  padding-right: 13rem;

}
.px-56 {

  padding-left: 14rem;
  padding-right: 14rem;

}
.px-60 {

  padding-left: 15rem;
  padding-right: 15rem;

}
.px-64 {

  padding-left: 16rem;
  padding-right: 16rem;

}
.px-72 {

  padding-left: 18rem;
  padding-right: 18rem;

}
.px-80 {

  padding-left: 20rem;
  padding-right: 20rem;

}
.px-96 {

  padding-left: 24rem;
  padding-right: 24rem;

}
.px-px {

  padding-left: 1px;
  padding-right: 1px;

}