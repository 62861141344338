.py-0 {

  padding-top: 0px;
  padding-bottom: 0px;

}
.py-1 {

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

}
.py-2 {

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

}
.py-3 {

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

}
.py-4 {

  padding-top: 1rem;
  padding-bottom: 1rem;

}
.py-5 {

  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

}
.py-6 {

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

}
.py-7 {

  padding-top: 1.75rem;
  padding-bottom: 1.75rem;

}
.py-8 {

  padding-top: 2rem;
  padding-bottom: 2rem;

}
.py-9 {

  padding-top: 2.25rem;
  padding-bottom: 2.25rem;

}
.py-10 {

  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

}
.py-11 {

  padding-top: 2.75rem;
  padding-bottom: 2.75rem;

}
.py-12 {

  padding-top: 3rem;
  padding-bottom: 3rem;

}
.py-14 {

  padding-top: 3.5rem;
  padding-bottom: 3.5rem;

}
.py-16 {

  padding-top: 4rem;
  padding-bottom: 4rem;

}
.py-20 {

  padding-top: 5rem;
  padding-bottom: 5rem;

}
.py-24 {

  padding-top: 6rem;
  padding-bottom: 6rem;

}
.py-28 {

  padding-top: 7rem;
  padding-bottom: 7rem;

}
.py-32 {

  padding-top: 8rem;
  padding-bottom: 8rem;

}
.py-36 {

  padding-top: 9rem;
  padding-bottom: 9rem;

}
.py-40 {

  padding-top: 10rem;
  padding-bottom: 10rem;

}
.py-44 {

  padding-top: 11rem;
  padding-bottom: 11rem;

}
.py-48 {

  padding-top: 12rem;
  padding-bottom: 12rem;

}
.py-52 {

  padding-top: 13rem;
  padding-bottom: 13rem;

}
.py-56 {

  padding-top: 14rem;
  padding-bottom: 14rem;

}
.py-60 {

  padding-top: 15rem;
  padding-bottom: 15rem;

}
.py-64 {

  padding-top: 16rem;
  padding-bottom: 16rem;

}
.py-72 {

  padding-top: 18rem;
  padding-bottom: 18rem;

}
.py-80 {

  padding-top: 20rem;
  padding-bottom: 20rem;

}
.py-96 {

  padding-top: 24rem;
  padding-bottom: 24rem;

}
.py-px {

  padding-top: 1px;
  padding-bottom: 1px;

}