.editable-buttons
{
    display: inline-block;
    box-sizing: border-box;
    
    .btn
    {
        top: 0 !important;
    }
}

.editable {
    text-decoration: none;
    border-bottom: 2px dashed #acb1be;
    color: inherit;
    
 
}

   .editable-input
    {
        
    label
    {
        font-weight: 400;
    }
    
    input 
    {
        font-weight: 400;
    }
    }
    
    
   .editable-container + span {
    display: none;
} 