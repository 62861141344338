// shared styles
.btn, .btn-flat {
  @extend %font-size-4;
  border: none;
  border-radius: 2px;
  display: inline-block;
  // margin-bottom: 15px;
  padding: rem(15);
  margin-left: rem(15);
  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
  
  &.pull-left
  {
      margin-left: 0;
      margin-right: rem(15);
  }
  
  &.pull-right
  {
      margin-right: 0;
      margin-left: rem(15);
  }
  
  &:first-child
  {
      //margin-right: rem(15);
  }
  
}
// Disabled shared style
.btn.disabled, .btn-floating.disabled, .btn-large.disabled, .btn:disabled, .btn-large:disabled, .btn-floating:disabled, .btn[disabled] {
  background-color: #cccccc !important;
  box-shadow: none;
  color: #ffffff !important;
  cursor: default;
  * {
    pointer-events: none;
  }

  &:hover { 
    background-color: #cccccc;
    color: #ffffff;
  }
}

// Buttons inside H2
h2 .btn
{
  position: relative;
  top: -(rem(15)); 
}

// Raised Button
.btn {
  text-decoration:none;
  color: $button-color-raised;
  background-color: $button-color;
  text-align: center;
  letter-spacing: .5px;
  @extend .z-depth-1;
  @include transition(.2s ease-out);
  cursor: pointer;

  &:hover {
    background-color: lighten($button-color, 5%);
    @extend .z-depth-1-half;
  }
}

.btn-xs
{
    background: transparent;
    border: none;
    color: $orange;
    
    &:focus
    {
        background: $orange;
        color: #ffffff;
    }
    
}

// Fixed Action Button
.fixed-action-btn {
  &.active {
    ul {
     visibility: visible;
    }
  }
  &.horizontal {
    padding: 0 0 0 15px;
    ul {
      text-align: right;
      right: 64px;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      left: initial;
      width: 500px; /*width 100% only goes to width of button container */

      li {
        display: inline-block;
        margin: 15px 15px 0 0;
      }
    }
  }
  position: fixed;
  right: 23px;
  bottom: 23px;
  padding-top: 15px;
  margin-bottom: 0;
  z-index: 998;

  ul {
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: 64px;
    margin: 0;
    visibility: hidden;

    li {
      margin-bottom: 15px;
    }

    a.btn-floating {
      opacity: 0;
    }
  }
}

// Large button
.btn-large {
  @extend .btn;
  height: $button-height * 1.5;
  line-height: 56px;

  i {
    font-size: $button-large-icon-font-size;
  }
}

.btn-small
{
  @extend .btn;
  @extend %font-size-6;
  text-transform: none;
  padding: rem(10);
  min-width: rem(40);
}

.btn-icon-sm
{
    @extend .btn;
  font-size: rem(20);
  text-transform: none;
  padding: rem(10);
  min-width: rem(40);
  margin-left: rem(5);
  border-radius: 50%;
  
  .fa-edit
  {
  }
  
}

// Block button
.btn-block {
  display: block;
}
