.map {
  width: 100%;
  max-width: 100%;

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.pl-circle-scale {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

#request-container {
  display: none;
  margin-bottom: rem(40);
}

.map-preview-row .not-selectable {
  //cursor: default !important;
  //pointer-events: none !important;
  opacity: 0.6;
}

.map .not-selectable {
  cursor: default !important;

  .section-shape {
    fill: #cfd0c4 !important;
  }
  .section-wh {
    fill: #cfd0c4 !important;
  }
  .section-wh + path {
    fill: #cfd0c4 !important;
  }
  text {
    fill: #cfd0c4 !important;
  }
}

.map .svg-section {
  cursor: pointer;

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */

  transition: .2s all ease;

  .section-shape {
    fill: #FFC781;

    transition: .2s all ease;
  }
  .section-wh {
    fill: #FFFFFF;

    transition: .2s all ease;
  }
  text {
    transition: .2s all ease;
    fill: #333333;
  }

  * {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
  }
}

.map .svg-section:hover {
  .section-shape {
    fill: #ffffff !important;
  }
  .section-wh {
    fill: #333333 !important;

  }
  .section-wh + path {
    fill: #ffffff !important;
  }
  text {
    fill: #333333 !important;
  }
}

.map .svg-section.pl-selected {
  .section-shape {
    fill: #333333 !important;
  }
  .section-wh {
    fill: #333333 !important;

  }
  .section-wh + path {
    fill: #ffffff !important;
  }

  text {
    fill: #ffffff !important;
  }

}

.map text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none
}

.map-container {
  position: relative;
  max-width: 60%;
  margin: auto;
}

.map-preview {
  box-sizing: border-box;
  background-color: #ffffff;
  position: absolute;

  padding: 0px;
  min-width: 0px;
  min-height: 0px;
  transition: all .2s ease, top 1ms, left 1ms;

  transform-origin: top left;

  transform: scaleX(0) scaleY(0);

  padding: 25px 30px 25px 25px;

  opacity: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;

  @extend .z-depth-4;

  .spinner {
    //   opacity: 0;
    //transform: scaleX(0.5) scaleY(0.5);
    position: absolute !important;
    top: 50%;
    left: 50%;
  }

  h2 {
    margin: 0;
    margin-bottom: 10px;
  }

  h6 {
    margin-bottom: 0;
    text-align: center;
    font-size: 10px;
    padding-left: rem(34);
  }

  &.visible {
    opacity: 1;
    min-height: 120px;
    min-width: 120px;

  }

  &.active {
    transform: scaleX(1) scaleY(1);
  }

}

.map-preview-subtitle {
  text-transform: uppercase;
  font-weight: 300;
  float: right;
  color: $gray-on-dark;
  margin-left: rem(20);
}

.map-preview-legend {
  @extend .clearfix;
  margin-top: 15px;

  .map-preview-seat {
    margin: 0;
    vertical-align: middle;
  }

  .map-preview-legend-column {
    float: left;
    //margin: right;
    margin-left: 25px;

    &:first-child {
      margin-left: 0;
    }

    & > div {
      margin-top: 0px;
    }
  }

  p {
    margin: 0;
    margin-left: 10px;

    /* margin-left: 20px; */
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
  }

}

.map-preview-seats-container {
  margin-top: 5px;
}

.map-preview-row {
  white-space: nowrap;
  height: rem(15);

  @extend .clearfix;

  .row-label {
    font-size: 9px;
    float: left;
    width: 30px;
    text-align: left;
    position: relative;
    top: 5px;
    
    cursor: pointer;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -o-user-select: none;
    user-select: none;
  }
}

.map-preview-seat {
  width: 8px;
  height: 8px;
  background-color: red;
  display: inline-block;
  margin-left: 4px;
  position: relative;
}



.seat-selector-header {
  text-align: center;
  margin: rem(10) 0;
  position: relative;
  display: block;

  .ui-dropdown {
    position: absolute;
    right: -(rem(30));
    top: 0;
  }
}

.seat-selector-wrapper {
  margin: 0;
  overflow: auto;
}

.seat-selector {
  position: relative;
  display: table;
  width: 100%;
}

.seat-selector-assign {
  margin-botton: 2rem;
}

.seat-selector-row {
  @extend .clearfix;

  white-space: nowrap;
  margin: 10px 0px;
  overflow: hidden;
}

.seat-selector-mid {
  white-space: nowrap;
  display: block;
  margin-left: 30px;
  margin-right: 50px;
}

.seat-selector-label {
  font-size: 20px;
  float: left;
  width: 30px;
  margin-top: 11px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}

.seat-selector-label-right {
  float: right;
  margin-left: rem(20);
}

.seat-selector-seat {
  width: 30px;
  height: 30px;
  background-color: red;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: 8px;

  text-align: center;
  /* padding-top: 6px; */
  box-sizing: border-box;
  /* border: 2px solid red; */
  vertical-align: middle;
  display: inline-block;
  position: relative;

  transition: all 0.2s;

  span {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none
  }

  &.seat-selected {
    background: $blue !important;

    &:before {
      color: #ffffff !important;
    }
  }
}

.seat-selectable {
  cursor: pointer;
}

.print-seat-status-no-scale {
  background: $mid-gray !important;
}
// No print selected
.print-seat-status-free {
  background: $green !important;
}
// Available
.print-seat-status-available {
  background: $green !important;
}
// Ready to print = Order already created
.print-seat-status-ready {
  background: $yellow !important;
}
// On Hold
.print-seat-status-held {
  background: $green !important;
  border-radius: 50%;
}
// Hold Status Assigned
.print-seat-status-assigned {
  background: $lighter-blue !important;
  border-radius: 50%;
}

// Hold Status Pending
.print-seat-status-pending {
  background: $salmon !important;
  border-radius: 50%;
}

.print-seat-status-printed {
  background: $gray !important;
}

.print-seat-status-scanned {
  background: $orange !important;
  border-radius: 50%;
}

// Status: Assigned
.seat-status-1 {
  background: $green;
  border-radius: 50%;
}

// Status: Hold
.seat-status-2 {
  background: $green;
}

// Status: Sold
.seat-status-4 {
  background: $orange;
  border-radius: 50%;
}

// Status: Available
.seat-status-0 {
  background: $mid-gray;
}

// Normal
.seat-type-0 {

}

// Disabled
.seat-type-1 {
  background-color: transparent !important;
  /* color: #333; */
  border: 2px dashed #e1e1e1;

  span {
    color: transparent;
  }
}

.seat-type-2 {
  span {
    display: none;
  }
  &:before {
    content: "\f193";
    display: inline;
    font-family: FontAwesome;
    font-size: 14px;
    color: #000000;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Limited View
.seat-type-6 {
  span {
    display: none;
  }
  &:before {
    content: "\f070";
    display: inline;
    font-family: FontAwesome;
    font-size: 14px;
    color: #000000;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Handicapped
.map-preview-seat.seat-type-2:before {
  content: "\f193";
  display: inline;
  font-family: FontAwesome;
  font-size: 8px;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Limited View
.map-preview-seat.seat-type-6:before {
  content: "\f070";
  display: inline;
  font-family: FontAwesome;
  font-size: 8px;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Spacer
.seat-type-3 {
  background-color: transparent !important;
  pointer-events: none !important;
}

.row-alignment-center {
  text-align: center;
}

.row-alignment-left {
  text-align: left;
}

.row-alignment-right {
  text-align: right;
}

.seats-selected-overlay {
  position: fixed;
  z-index: 10000;
  /* width: 200px; */
  display: block;
  /* height: 200px; */
  padding: 20px;
  background-color: #ffffff;
  transform: translate(-50%, -100%);
  left: 50%;
  top: 0px;
  display: none;
  @extend .z-depth-3;

  p {
    margin: 0;
  }

}

// Map selection

.selection {
  position: absolute;
  z-index: 90000;
  border: 2px solid rgba(4, 117, 111, 1);
  background-color: rgba(4, 117, 111, .6);
  cursor: default;

  &:hover {
    cursor: default;
  }
}

.de-selection {
  z-index: 90000;
  position: absolute;
  border: 2px solid rgba(220, 20, 60, 1);
  background-color: rgba(220, 20, 60, .6);
  cursor: default;

  &:hover {
    cursor: default;
  }
}

.svg-selection {
  stroke: rgba(4, 117, 111, .6);
  stroke-width: 4;
  fill: none;
}

.svg-de-selection {
  stroke: rgba(220, 20, 60, .6);
  stroke-width: 4;
  fill: none;
}

.seat-spacer-left {
  margin-left: 46px;
}

.seat-spacer-right {
  margin-right: 38px;
}

.preview-spacer-left {
  margin-left: 16px;
}

.preview-spacer-right {
  margin-right: 12px;
}

/**********************************************************
                             Legend
**********************************************************/

.heatmap-l {
  @extend .clearfix;
  display: none;

}

.heatmap-l-gradient {
  height: 10px;
  width: 100%;
  background: -moz-linear-gradient(90deg, #32CD32, #FFD700, #DC143C);
  background: -webkit-linear-gradient(90deg, #32CD32, #FFD700, #DC143C);
  background: linear-gradient(90deg, #32CD32, #FFD700, #DC143C);
  margin-top: 10px;
  margin-bottom: 10px;
}

.heatmap-l-title {
  @extend %font-size-6;
}

// New CSS
.map-interface-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}

.map-interface-holder {
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 1000;

  text-align: center;

  transform: translate(-50%, -50%) scaleX(1) scaleY(1);

  transition: all .3s ease;

  opacity: .5;

  &.active-btn {
    transform: translate(-125%, -50%) scaleX(1) scaleY(1);
    opacity: 1;
  }

  & + &.active-btn {
    transform: translate(25%, -50%) scaleX(1) scaleY(1);
  }

  div {
    @extend %font-size-6;

    position: relative;
    z-index: -1;

    opacity: 0;
    line-height: 1.6;
    padding: 5px 0px;
    border-radius: 4px;
    margin-top: 7px;
    font-weight: 300;
    color: white;
    background: rgba(0, 0, 0, 0.7);
    transition: all .3s ease;
    text-transform: none;
    transform: translateY(-100%);

    &.show-label {
      opacity: 1;
      transform: translateY(0);
    }
  }

}

.map-interface-button {
  border-radius: 50%;

  background-color: $orange;
  color: #ffffff;
  width: 60px;
  height: 60px;

  text-align: center;
  line-height: 60px;

  font-size: 25px;

  padding: 0;
  margin: 0;

  @extend .z-depth-2;

  i {
    color: white;
  }

  &:hover {
    @extend .z-depth-4;
  }

}

.map-preview-loading {
  position: relative;
  height: 100px;
}

.modal-loading {
  position: relative;
  height: 400px;

  .spinner {
    //   opacity: 0;
    //transform: scaleX(0.5) scaleY(0.5);
    position: absolute !important;
    top: 50%;
    left: 50%;
  }
}

// Scale Row Selector

.scale-by-row-container
{
  position: relative;
  display: block;
  left: -60px;
}

.scale-row-selector
{
    margin-left: 60px;
    margin-bottom: 40px;

    display: inline-block;
    position: relative;
    vertical-align: top;
    
    h2 {
      margin: 0;
      margin-bottom: 10px;
    }

    h6 {
      margin-bottom: 0;
      text-align: center;
      font-size: 10px;
      padding-left: rem(34);
    }
}

.scale-row-selector .map-preview-seat {
    
    cursor: pointer;
  
    background-color: $mid-gray;
  
    &.seat-selected
    {
      background: $blue !important;
    }
}