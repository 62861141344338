.center-container
{
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.signin-body
{
    height: 100%;
    width: 100%;   
    
    background: url(https://tixby-public.s3.amazonaws.com/img/party5low.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.center-child
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.signin-modal
{
    @extend .z-depth-4;
    background-color: rgba(250,250,250, 1);
    width: 500px;
    margin: auto;
    font-weight: 100;
    
}

.tixby-logo
{
    position: absolute;
    top: 25px;
    left: 25px;
}


.portal-link{
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 1.6rem;
    
    a {
        text-decoration: none;
        color: $white;
        transition: all 200ms ease;
        box-sizing: border-box;
        
        &:hover
        {
            color: #acb1be;
        }
    }
}
