/*!
 * Datetimepicker for Bootstrap 3
 * ! version : 4.7.14
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}


$bs-datetimepicker-timepicker-font-size: rem(14) !default;
$bs-datetimepicker-active-bg: $btn-primary-bg !default;
$bs-datetimepicker-active-color: #ffffff !default;
$bs-datetimepicker-border-radius: $global-radius !default;
$bs-datetimepicker-btn-hover-bg: $mid-gray !default;

$bs-datetimepicker-disabled-color: $button-disabled !default;
$bs-datetimepicker-alternate-color: $button-disabled !default;
$bs-datetimepicker-secondary-border-color: #ffffff;
$bs-datetimepicker-secondary-border-color-rgba: transparent !default;
$bs-datetimepicker-primary-border-color: #ffffff !default;
$bs-datetimepicker-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !default;

.bootstrap-datetimepicker-widget > .row {
  white-space: nowrap;
}


.bootstrap-datetimepicker-widget {
  list-style: none;
  font-size: rem(14);
  overflow: auto;
  margin-right: -100000px;

  .timepicker {
    margin-left: 20px;
    max-width: 400px;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }

  .datepicker {
    display: inline-block;
    vertical-align: middle;
    max-width: 500px;
    white-space: normal;
  }

  .btn {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    color: #333333;

    &:hover {
      background-color: transparent;
      box-shadow: inherit;
    }
  }

  button.btn {
    padding: 0 rem(15) !important;
    font-weight: $weight-bold;
  }


  &.dropdown-menu {

    padding: rem(10) rem(20);

    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    /* min-width: 160px; */
    /* padding: 5px 0; */
    /* margin: 2px 0 0; */
    /* font-size: 14px; */
    /* text-align: left; */
    /* list-style: none; */
    background-color: #fff;
    @extend .z-depth-2;
    /* -webkit-background-clip: padding-box; */
    /* background-clip: padding-box; */
    /* border: 1px solid #ccc; */
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    /* border-radius: 4px; */
    /* -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */
    /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */

    &:before, &:after {
      content: '';
      display: inline-block;
      position: absolute;
    }

    &.bottom {
      &:before {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $bs-datetimepicker-secondary-border-color;
        border-bottom-color: $bs-datetimepicker-secondary-border-color-rgba;
        top: -7px;
        left: 7px;
      }

      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $bs-datetimepicker-primary-border-color;
        top: -6px;
        left: 8px;
      }
    }

    &.top {
      &:before {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid $bs-datetimepicker-secondary-border-color;
        border-top-color: $bs-datetimepicker-secondary-border-color-rgba;
        bottom: -7px;
        left: 6px;
      }

      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $bs-datetimepicker-primary-border-color;
        bottom: -6px;
        left: 7px;
      }
    }

    &.pull-right {
      &:before {
        left: auto;
        right: 6px;
      }

      &:after {
        left: auto;
        right: 7px;
      }
    }
  }

  .list-unstyled {
    margin: 0;
  }

  /*
  a[data-action] {
      padding: 6px 0;
  }
  */

  a[data-action]:active {
    box-shadow: none;
  }

  .timepicker-hour, .timepicker-minute, .timepicker-second {
    font-size: $bs-datetimepicker-timepicker-font-size;
    margin: 0;
  }

  button[data-action] {
    padding: 6px;
  }

  .btn[data-action="incrementHours"]::after {
    @extend .sr-only;
    content: "Increment Hours";
  }

  .btn[data-action="incrementMinutes"]::after {
    @extend .sr-only;
    content: "Increment Minutes";
  }

  .btn[data-action="decrementHours"]::after {
    @extend .sr-only;
    content: "Decrement Hours";
  }

  .btn[data-action="decrementMinutes"]::after {
    @extend .sr-only;
    content: "Decrement Minutes";
  }

  .btn[data-action="showHours"]::after {
    @extend .sr-only;
    content: "Show Hours";
  }

  .btn[data-action="showMinutes"]::after {
    @extend .sr-only;
    content: "Show Minutes";
  }

  .btn[data-action="togglePeriod"]::after {
    @extend .sr-only;
    content: "Toggle AM/PM";
  }

  .btn[data-action="clear"]::after {
    @extend .sr-only;
    content: "Clear the picker";
  }

  .btn[data-action="today"]::after {
    @extend .sr-only;
    content: "Set the date to today";
  }

  .picker-switch {
    text-align: center;

    &::after {
      @extend .sr-only;
      content: "Toggle Date and Time Screens";
    }

    td {
      padding: 0;
      margin: 0;
      height: auto;
      width: auto;
      line-height: inherit;

      span {
        line-height: 2.5;
        height: 2.5em;
        width: 100%;
      }
    }
  }

  table {
    width: 100%;
    margin: 0;

    tbody tr:last-child {
      border-bottom: solid 3px transparent !important;
    }

    & td,
    & th {
      text-align: center;
      border-radius: $bs-datetimepicker-border-radius;
    }

    & th {
      height: 20px;
      line-height: 20px;
      width: 20px;

      &.picker-switch {
        width: 145px;
      }

      &.disabled,
      &.disabled:hover {
        background: none;
        color: $bs-datetimepicker-disabled-color;
        cursor: not-allowed;
      }

      &.prev::after {
        @extend .sr-only;
        content: "Previous Month";
      }

      &.next::after {
        @extend .sr-only;
        content: "Next Month";
      }
    }

    & thead tr:first-child th {
      cursor: pointer;

      &:hover {
        background: $bs-datetimepicker-btn-hover-bg;
      }
    }

    & td {
      height: 54px;
      line-height: 54px;

      &.cw {
        font-size: .8em;
        height: 20px;
        line-height: 20px;
        color: $bs-datetimepicker-alternate-color;
      }

      &.day {
        height: 20px;
        line-height: 20px;
        width: 20px;
        padding: 10px;
      }

      &.day:hover,
      &.hour:hover,
      &.minute:hover,
      &.second:hover {
        background: $bs-datetimepicker-btn-hover-bg;
        cursor: pointer;
      }

      &.old,
      &.new {
        color: $bs-datetimepicker-alternate-color;
      }

      &.today {
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          border-top-width: 7px;
          border-top-style: solid;
          border-right-style: solid;
          border-right-color: transparent;
          border-left-style: solid;
          border-left-color: transparent;
          border-bottom-style: solid;
          border-bottom-color: $bs-datetimepicker-active-bg;
          border-top-color: $bs-datetimepicker-secondary-border-color-rgba;
          position: absolute;
          bottom: 4px;
          right: 4px;
        }
      }

      &.active,
      &.active:hover {
        background-color: $bs-datetimepicker-active-bg;
        color: $bs-datetimepicker-active-color;
        text-shadow: $bs-datetimepicker-text-shadow;
      }

      &.active.today:before {
        border-bottom-color: #fff;
      }

      &.disabled,
      &.disabled:hover {
        background: none;
        color: $bs-datetimepicker-disabled-color;
        cursor: not-allowed;
      }

      span {
        display: inline-block;
        white-space: nowrap;
        min-width: 54px;
        line-height: 54px;
        box-sizing: border-box;
        padding: 0 10px;
        margin: 2px 1.5px;
        cursor: pointer;
        border-radius: $bs-datetimepicker-border-radius;

        &:hover {
          background: $bs-datetimepicker-btn-hover-bg;
        }

        &.active {
          background-color: $bs-datetimepicker-active-bg;
          color: $bs-datetimepicker-active-color;
          text-shadow: $bs-datetimepicker-text-shadow;
        }

        &.old {
          color: $bs-datetimepicker-alternate-color;
        }

        &.disabled,
        &.disabled:hover {
          background: none;
          color: $bs-datetimepicker-disabled-color;
          cursor: not-allowed;
        }
      }
    }
  }

  &.usetwentyfour {
    td.hour {
      height: 27px;
      line-height: 27px;
    }
  }
}

.input-group.date {
  & .input-group-addon {
    cursor: pointer;
  }
}

.dt-close {
  color: #ffffff;
  background: $orange;
  width: 8rem !important;
  float: right !important;
}